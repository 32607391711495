import React from 'react'
import Footer from '../../Components/Common/Footer/Footer';
import Navbars from '../../Components/Common/Navbar/Navbars';
import CrestJourney from '../../Components/CrestJourney/CrestJourney'

import Tabs from '../../Components/Tabs/Tabs';
import Scrolltop from '../../Components/ScrollControl/ScrollControl';
import Term_Docs from '../../Components/Documents/Term_Docs';
const About = () => {
  return (
    <div>
      <Navbars />
      <Tabs />
      <CrestJourney />
    
      <Scrolltop/>
      <Footer />

    </div>
  )
}

export default About;

import React from 'react';
import Navbars from '../../Components/Common/Navbar/Navbars'
import Faq from '../../Components/Faq/Faq'
import HomeBanner from '../../Components/Home-Banner/ Homebanner';
 
import IndianBelief from '../../Components/IndianBelief/IndianBelief'
import Footer from '../../Components/Common/Footer/Footer'
import Insurance  from '../../Components/Career/Career'
import Importance from '../../Components/Insurance-Importance/Importance';
import HelpVideo from '../../Components/HelpVideos/HelpVideo';
import Scrolltop from '../../Components/ScrollControl/ScrollControl';
 

const Home = () => {
  return (
    <div>
      <Navbars />
      
      <HomeBanner />
    
      <Importance/>
      <Insurance/>
      {/* <IndianBelief /> */}
      <HelpVideo/>
      <Faq />
      <Scrolltop/>
      <Footer />
   
     


    </div>
  );
}

export default Home;

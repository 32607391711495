import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../../CSS/Tab.css'


const Tabs = () => {
  let navigate =useNavigate();

  const handleMotor = () => { 
    navigate ('/policies/motor-insurance')
  }

  const handleHealth =()=>{
    return navigate ('/policies/health-insurance')
  }

  const handleTerm =()=>{
    return navigate ('/policies/term-insurance')
  }
  const handleCorporate =()=>{
    return navigate ('/policies/corporate-insurance')
  }

  return (
    <div className='row d-flex justify-content-center my-4 mx-3'>
      
   
       <div onClick={handleTerm}  className='routes'> Term Insurance</div>
       <div onClick={handleHealth} className='routes'>  Health Insurance</div>
       <div onClick={handleMotor} className='routes'>  Motor Insurance</div>
       <div onClick={handleCorporate} className='routes text-center'>  Corporate Insurance</div>
 

    </div>
  )
}

export default Tabs;
import React from 'react'
import Faq_accordian from '../Common/FaqAccordian/FaqAccordian'
import { Container } from 'react-bootstrap'



export const MOCK_FAQ_VALUES = [
  {
    id: 1,
    title: 'Is the return of the premium option feasible? ',
    body: '  Return of the premium is generally considered a good option however is usually very misleading and highly misunderstood by most people. The premium of policies with this benefit is usually high as compared to others thus the benefit derived from the return of premium is overthrown by the interest which is forgone by paying the additional amount that means if the amount was invested instead of purchasing the option the person could have earned more benefit.'
  },
  {
    id: 2,
    title: 'What is a critical illness rider is life insurance?',
    body: 'A critical illness rider is an additional cover that helps the policyholder financially in the case they are diagnosed with a critical illness during their policy tenure. It is a life threatening disease such as cancer. The benefit received thus can be used for further treatment of such diseases.'
  },
  {
    id: 3,
    title: 'What is a "Switch-on/Switch-off" insurance policy? ',
    body: ` This insurance scheme permits vehicle owners to switch 'on' and 'off' the policy whenever they want. The insurance is calculated on the age and experience of the driver. Customers can use the mobile application to switch their policy cover 'on' and 'off', depending on whether they are driving that day. However, "the vehicles will be covered the whole year against fire and theft, even if the policy is switched off at that time since these incidents can happen even if the vehicle is not being driven`
  },
  {
    id: 4,
    title: '	What is NCB?',
    body: ' No claim bonus is a feature of insurance policies wherein insurers tend to increase the basic sum insured by a specific percentage in the claim-free years when the insured does not utilizes his/her sum insured. This is subjective which insurer one chooses. This benefit however is confined to limits according to the terms and conditions of the plan or policy chosen.    '
  },
  {
    id: 5,
    title: 'What is the difference between comprehensive and third-party motor insurance?',
    body: 'Third-party insurance only covers one damage caused to any other person or property and is mandated under the motors act. However, a comprehensive covers the vehicle’s damage along with the third-party requirements.'
  },
  {
    id: 6,
    title: '	What are the documents required to be submitted for a Motor Insurance claim?',
    body: 'Generally, the following documents are required to be submitted. However, read through your policy to see the complete list—duly filled in claim form, RC copy of the vehicle, original estimate of loss, Original repair invoice and payment receipt. In case cashless facility is availed, only repair invoice would need to be submitted and FIR, if required. For theft claims, the keys are to be submitted. Theft claims would also require a non-traceable certificate to be submitted.'
  },
  {
    id: 7,
    title: 'Why do premiums vary among different insurance companies?',
    body: 'Different Insurance companies uses different statistics and different methods to calculate premiums and hence the value of premiums varies.'
  },
  {
    id: 8,
    title: 'What is Travel Insurance?',
    body: 'Travel insurance is the insurance that protects you and your family when you are out for vacation overseas or out of the station. it covers the expense of medical treatment or in case of an accident. other losses such as loss of personal documents baggage or essentials are also covered under this policy. it is the best option for people traveling abroad as it covers many uncertainties that can arise in a foreign land which can be difficult to manage at times.'
  },
  {
    id: 9,
    title: 'What are Schengen countries?',
    body: 'Schengen countries are those European countries where it is mandatory to have a travel insurance policy in order to get a visa for such countries. Countries such as Austria, Belgium, Finland, etc. are Schengen countries.'
  },
  {
    id: 10,
    title: 'What is deductible?',
    body: 'Deductible in travel insurance is the portion of expenses that you pay out of your own pocket before your travel insurance policy begins paying the balance amount of the claim.'
  },
  {
    id: 11,
    title: 'What benefits do I get in Health Insurance?',
    body: 'Health insurance covers one from hospitalization expenses additionally it carries various other benefits such as an increase in the SI via the no claim bonus, reinstatement benefit, Tax benefit under 80C/80D and most importantly it reduces burden of expenses from the shoulders of the insured.'
  },
  {
    id: 12,
    title: 'What is Health Insurance?',
    body: 'Health insurance as the name suggests is a type of insurance that covers the insured from all the expenses arising due to medical issues to the insured that is all the hospitalization expenses incurred in case of a disease.'
  },
  {
    id: 13,
    title: 'What is Co-pay?',
    body: 'Co-pay is another feature of general insurance wherein a certain amount of the incurred expenses has to be paid by the insured. This is generally a pre-agreed percentage when the policy has signed the percentage of the claim amount that the policyholder has to pay from their own pocket under a health insurance policy'
  },
  {
    id: 14,
    title: 'What is capping in Health Insurance? ',
    body: 'It is the limit on hospital expenses as per the policy terms differs from company to company. The insurer’s liability to pay for such expenses is confined to the limit or capped amount or percentage of sum insured.'
  },
  {
    id: 15,
    title: 'What is NCB?',
    body: 'No claim bonus is a feature of insurance policies wherein insurers tend to increase the basic sum insured by a specific percentage in the claim-free years when the insured does not utilizes his/her sum insured. This is subjective which insurer one chooses. This benefit however is confined to limits according to the terms and conditions of the plan or policy chosen.'
  },
  {
    id: 16,
    title: 'What are the main exclusions in marine insurance?',
    body: `Marine Insurance doesn't offer any coverage in the following cases: Loss or damage due to the willful act of negligence and misconduct. Any loss or damage due to delay. Loss or damage due to improper packing.`
  },
  {
    id: 17,
    title: 'What is the CAR policy?',
    body: `Contractor's All Risk Insurance offers comprehensive and adequate protection against loss or damage in respect of contract works, construction plant and equipment and/ or construction machinery, as well as third party claims in respect of property damage or bodily injury arising in connection with the execution of a project. `
  },
  {
    id: 18,
    title: 'Why does India has less Insurance Penetration? ',
    body: 'Lack of financial literacy and financial planning in India and uneven distribution of the resources in urban and rural areas is the main reason for less Insurance penetration in India. People in India are generally unknown of the various insurance products available and the process of getting the best suitable insurance policy.'
  },
  {
    id: 19,
    title: 'What do you mean by risk management?',
    body: `Risk management is the process of identifying, assessing, and controlling threats to an organization's / individual’s capital and earnings. These risks stem from a variety of sources including financial uncertainties, legal liabilities, technology issues, strategic management errors, accidents, and natural disasters.`
  },
  {
    id: 20,
    title: 'Why is Insurance necessary?',
    body: 'Insurance Is like our best friend always at there with us in our bad times. Insurance converts over uncertainties into the insurer’s risks. It helps in financial management and expense smoothing as it helps in covering the uncertainties. It is essential for the safety of our present and future generations. It helps to prevent the pressure on our pockets in case of any mishappening at a very nominal amount.'
  },
  {
    id: 21,
    title: 'What is personal finance?',
    body: 'Personal finance is a term that covers managing your money as well as saving and investing. It encompasses budgeting, banking, insurance, mortgages, investments, retirement planning, and tax and estate planning.'
  },
  {
    id: 22,
    title: 'Which is better: ULIP or Traditional?',
    body: 'Both of these plans aim at serving two different purposes one is capital appreciation and another one is protection. ULIP plans are however market-oriented and thus are more beneficial and lucrative as these are handled by professional fund managers and strategic professionals thus they tend to be more likable due to a decent rate of interest and CAGR.'
  },
  {
    id: 23,
    title: 'How to Invest money by buying Insurance?',
    body: 'Insurance is not only about protection. nowadays there are many new policies wherein the insured can invest an amount for the purpose of capital appreciation along with protection. ulip plans and life investment plans serve this purpose. they are very famous nowadays because of their dual benefit however they are subject to market risk and failures.'
  },
  {
    id: 24,
    title: 'What are the ways to earn by selling Insurance?',
    body: 'There are many ways by which one can earn by selling insurance. being an advisor and agent or a posp with a broker opens horizons for these people as insurance has a high commission on sale these entrepreneurs can earn a decent amount by selling these products. brokers also tend to pay more to their posp’s because the earnings in this industry are very much.'
  },
  {
    id: 25,
    title: 'What is the minimum eligibility to become an insurance advisor?',
    body: 'As per the IRDAI guidelines, anyone who is qualified in his/her 10th grade is qualified to become an insurance advisor or agent.'
  },
  {
    id: 26,
    title: 'Who is POSP?',
    body: 'A POSP is a point of sale person that is the advisor affiliated with an insurance broker who is actually the link between the customer and the broker and is the one who actually closes the sale. '
  },
]

const AllFaq = () => {
  return (
    <>

      <div className='mb-5' id={`section-1`} >


          <div className='text-center my-5'   ><h2>FAQ</h2></div>
          <Container>
            <div className='row mb-md-5 mb-4'>
              {MOCK_FAQ_VALUES.map((value, index) => {
                return (
                  <div className='col-12 col-md-6 mb-4' key={index}>
                    <Faq_accordian details={value} />
                  </div>
                )
              })}
            </div>
          </Container>
      
      </div>

    </>
  )
}

export default AllFaq

import React, { useRef } from 'react'
import Footer from '../../Components/Common/Footer/Footer'
import Navbars from '../../Components/Common/Navbar/Navbars'
import PoliciesTabs from '../../Components/PoliciesTabs/PoliciesTabs'
import { Scrollspy } from "@makotot/ghostui";
import ScrollToTop from '../../Components/ScrollControl/ScrollControl';
import Container from 'react-bootstrap/Container';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Motor_Insurance = () => {

  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),

  ];
  return (
    <div>
      <Navbars />
      <PoliciesTabs />
      <h1 className='text-center'>Motor Insurance</h1>
      <Scrollspy sectionRefs={sectionRefs} >
        {({ currentElementIndexInViewport }) => (
          <div className='d-md-flex '>
            <div className=" position-md-sticky top-5 " style={{ width: '300px' }}>
              <div className="  position-sticky t-v  pt-md-5 pt-2" style={{ width: '300px' }} >
                <ul
                  data-cy="nav-wrapper"
                  className="space-y-5 cursor-pointer mt-12"
                >
                  <li
                    className={
                      currentElementIndexInViewport === 1
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-1`}> What is Motor Insurance</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 2
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-2`}>Some stats for India in 2020</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 3
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-3`}>  Would it not be really great to have someone who can bear all these losses?</a>
                  </li>
              
                  <li
                    className={
                      currentElementIndexInViewport === 5
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-5`}>Types of Motor Insurance</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 6
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-6`}>No Claim Bonus</a>
                  </li>

                  <li
                    className={
                      currentElementIndexInViewport === 7
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-7`}>Deductibles</a>
                  </li>

                  <li
                    className={
                      currentElementIndexInViewport === 8
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-8`}> Depreciation</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 9
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-9`}> Insured Declared Value (IDV)</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 10
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-10`}>Add On Features</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 11
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-11`}>Personal Accident Cover</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className=" px-md-5 px-4">
              <div data-cy="section-wrapper" className='px-md-3'>
                <div
                  id={`section-1`}
                  ref={sectionRefs[1]}
                  className={
                    currentElementIndexInViewport === 1 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mt-5"
                    id="entrepreneur"
                  >
                    <h2>What is Motor Insurance</h2>
                  </div>
                  <p className="description mt-4" >
                    We can’t imagine a day without commute
                    in our life. Bikes, Scooty, Cars and
                    other modes of transport have become a precious
                    and necessary part of our life. Such
                    precious resources need care and protection
                    from any damage.
                  </p>
                </div>
                <div
                  id={`section-2`}
                  ref={sectionRefs[2]}
                  className={
                    currentElementIndexInViewport === 2 ? "active" : ""
                  }
                >
                  <div
                    className=" journey-title text-center mb-1 mt-md-5 mt-4"
                    id="parttime"
                  >
                    <h2>  Some stats for India in 2020</h2>
                  </div>
                  <p className=" description mt-4">

                    <Row xs={3} md={3} sm={3} lg={3}>
                      <Col >Road Accidents reported </Col>
                      <Col> Death in Road Accidents</Col>
                      <Col>Injuries in Road Accidents</Col>
                    </Row>

                    <br />

                    <Row>
                      <Col>3,66,138 @ 1,003 per day </Col>
                      <Col> 1,31,714 @ 361 per day</Col>
                      <Col>3,48,279 @ 954 per day</Col>
                    </Row>




                  </p>

                </div>

                <div
                  id={`section-3`}
                  ref={sectionRefs[3]}
                  className={
                    currentElementIndexInViewport === 3 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1 mt-md-5 mt-4"
                    id="fulltime"
                  >
                    <h2>  Would it not be really great to have someone who can bear all these losses?</h2>
                  </div>



                  <p className="description mt-4" >
                    Motor Insurance is the exact tool that protects vehicle
                    owners against such losses. It takes care
                    of all – damages caused to the vehicles
                    and properties and compensation needed
                    for people who died
                  </p>
                  <p className="description mt-4" >
                    In India, it is mandatory to have at least third party motor insurance for your vehicles!
                  </p>
                  <p className="description mt-4" style={{ fontWeight: '700' }} >
                    Facts:-


                  </p><br />
                  <p className="description " style={{ fontWeight: '700' }} >


                    It is also a truth that maximum road accident happens with 2-wheelers (43.5% during 2020) and 69% of accident victims were young adults in age group of 18 – 45 years

                  </p>                </div>



                <div
                  id={`section-5`}
                  ref={sectionRefs[5]}
                  className={
                    currentElementIndexInViewport === 5 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1  mt-md-5 mt-4"
                    id="ds"
                  >
                    <h2>Types of Motor Insurance</h2>
                  </div>


                  <p className="description mt-4" >
                    Based on the usage of your vehicle and anticipated risks, one can take different types of motor insurance policies.</p>
                  <p className="description mt-4" ><u>
                    <b>Third Party Liability Policy</b></u></p>
                  <p className="description mt-4" >
                    As the name suggests, this policy covers any damage caused to another person or property by your vehicle. This is also known as Legal liability policy,
                    as this is mandated by government rules as mandatory to have for every vehicle. While this policy covers damages caused to others by your
                    vehicle, this doesn’t cover any damages, whatsoever, caused to your own vehicle.</p>
                  <p className="description mt-4" ><u>
                   <b> Own Damage Policy</b></u></p>
                  <p className="description mt-4" >
                    This policy covers damages caused to your vehicle. However, this policy can be opted when you already have a third-party liability
                    policy for your vehicle. With the introduction of mandatory 3 / 5 years insurance at the time of purchase of a new vehicle, this policy has gained prominence.
                    Generally new vehicle owners, buy legal liability or third-party liability insurance for 3 / 5 years and own damage insurance for 1 year only to keep overall
                    cost of their new vehicle less. So, in subsequent years, this policy becomes the only option for them.</p>
                  <p className="description mt-4" ><u>
                   <b> Comprehensive Policy</b></u></p>
                  <p className="description mt-4" >
                    This policy is a combination policy, a mix of both Own damage and Third-party Legal liability policy. This policy covers damages caused to your own vehicle as well as loss caused to others by your vehicle.</p>
                </div>


                <div
                  id={`section-6`}
                  ref={sectionRefs[6]}
                  className={
                    currentElementIndexInViewport === 6 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1  mt-md-5 mt-4"
                    id="ds"
                  >
                    <h2> No Claim Bonus</h2>
                  </div>
                  <p className="description mt-4" >
                    Insurance companies offer a rebate in amount charged for vehicle insurance policies, if you have not taken any claim for damages, etc. in previous year of policy. This is called No Claim Bonus or
                    NCB. NCB is offered only on the amount charged for Own Damage portion of policy.
                    NCB starts at 20% and goes up to 50%, based on consecutive years of No Claim of a vehicle. In case, you take any claim amount for your vehicle, NCB becomes zero in the subsequent policy year.</p>
                  <p className="description mt-4" >

                    NCB starts at 20% and goes up to 50%, based on consecutive years of No Claim of a vehicle. In case, you take any claim amount for your vehicle, NCB becomes zero in the subsequent policy year.</p>


                </div>


                <div
                  id={`section-7`}
                  ref={sectionRefs[7]}
                  className={
                    currentElementIndexInViewport === 7 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1  mt-md-5 mt-4"
                    id="ds"
                  >
                    <h2>Deductibles</h2>
                  </div>
                  <p className="description mt-4" >
                    At the time of any vehicle claim settlement, you may have to pay certain amount from your pocket and rest is paid by the insurers. This amount that you pay from their pocket is called deductible</p>
                  <p className="description mt-4" >
                    There are 2 types of deductibles:</p>
                  <p className="description mt-4" ><u>
                   <b> Compulsory Deductible</b></u></p>
                  <p className="description mt-4" >
                    These are mandatory payments that a vehicle owner has to make, at the time of every claim settlement for their vehicles. These are fixed by insurers at the time of issuing a policy.</p>
                  <p className="description mt-4" ><u>
                   <b> Voluntary Deductible</b></u></p>
                  <p className="description mt-4" >
                    If you are really confident of your driving and believe that chances of meeting an accident is very slim for you, you may opt for voluntary deductible. This reduces premium amount payable by you, at the time of buying an insurance policy. However, remember, higher the deductible, higher is your out-of-pocket expenses at the time of any claim settlement.</p>


                </div>

                <div
                  id={`section-8`}
                  ref={sectionRefs[8]}
                  className={
                    currentElementIndexInViewport === 8 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1  mt-md-5 mt-4"
                    id="ds"
                  >
                    <h2> Depreciation</h2>
                  </div>
                  <p className="description mt-4" >
                    As the time passes, the condition of your vehicle deteriorates. This deterioration depends on the type of material used for making such parts or components.</p>
                  <p className="description mt-4" >
                    Insurance companies deduct certain amount at the time of claim settlement based on age of vehicle and material type used for the components. This deduction is called depreciation for the policy. Depreciation is based on a pre-defined percentage for each material types and is a standard policy in vehicle insurance.</p>
                </div>

                <div
                  id={`section-9`}
                  ref={sectionRefs[9]}
                  className={
                    currentElementIndexInViewport === 9 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1  mt-md-5 mt-4"
                    id="ds"
                  >
                   <h2> Insured Declared Value (IDV)</h2>
                  </div>
                  <p className="description mt-4" >
                    In plain English, this is the cost of your car ascertained by the insurer for claim purposes. Your premium is dependent on  this IDV. This amount is calculated based on age of your vehicle and after applying a pre-fixed depreciation percentage to the original cost of your vehicle.</p>
               <p className="description mt-4" >
                    In an unfortunate case of theft of your car or Total loss because of accidents, insurers pay this amount to you, unless a Return to Invoice add-on covered is bought (we talk about RTI in later section)</p>
                </div>

                <div
                  id={`section-10`}
                  ref={sectionRefs[10]}
                  className={
                    currentElementIndexInViewport === 10 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1  mt-md-5 mt-4"
                    id="ds"
                  >
                  <h2>Add On Features</h2>
                  </div>
                  <p className="description mt-4" >
                    Have you availed of some extra benefit, those upgrades that come at a little extra cost but offers you some exciting benefits? If yes, then you would relate to add-on features of motor insurance that offers such benefits at a very nominal cost.</p>

               <p className="description mt-4" >
                    Add-on features are additional features offered by insurers and hence are voluntary and optional. Some of the add-ons can be really a great value for money.</p>

               <p className="description mt-4" >
                    Some of the popular add-on features are:
               </p>
               <p className="description mt-4" ><u>
                   <b> Zero Depreciation</b></u></p>
               <p className="description mt-4" >
                    In previous section, we knew how Insurance companies deduct for depreciation at the time of claim settlement. What if, you do not want to pay this amount?</p>

               <p className="description mt-4" >
                    The answer is Zero Depreciation add-on cover.</p>
               <p className="description mt-4" >
                    If you buy a Zero Deprecation add-on cover, then at the time of claim settlement for any damages caused to the vehicle, insurers do not deduct any depreciation and pays the full amount of settlement</p>


               <p className="description mt-4" ><u>
                   <b> Consumable Cover</b> </u></p>
               <p className="description mt-4" >
                    Insurers do not pay for small items, like nut-bolts, oils, lubricants, etc. However, if you do not want to pay for this as well, you have an option to take Consumables Cover at a nominal cost.</p>
               <p className="description mt-4" >
                    This cover allows you to even cover for cost of smaller items, called consumables at the time of claim.</p>

               <p className="description mt-4" ><u>
                    <b>Engine Protect</b></u></p>
               <p className="description mt-4" >
                    What if, there is a damage caused to engine because of water ingress or lubricant leak or other such conditions that is not part of the standard policy coverage by insurers.</p>
               <p className="description mt-4" >
                    Would you not want someone to pay for all such events. Engine Protect covers these situations, and pays for any claim that may arise as such.</p>
               <p className="description mt-4" ><u>
                    <b>Return to Invoice</b></u></p>


               <p className="description mt-4" >
                    You must have heard of car thefts or of stories of vehicle accidents where vehicles are beyond repair and considered as total loss. While no one wants that, however, one must be prepared for all eventualities.</p>

               <p className="description mt-4" >
                    In such all cases, normally insurers pay an amount equal to Insured Declared Value or IDV, which we have discussed in earlier section.</p>

               <p className="description mt-4" >
                    However, in case you have paid for Return to Invoice feature, you would be paid your car’s invoice value including, registration costs and road taxes.</p>
                </div>

                <div
                  id={`section-11`}
                  ref={sectionRefs[11]}
                  className={
                    currentElementIndexInViewport === 11 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1  mt-md-5 mt-4"
                    id="ds"
                  >
                   <h2>Personal Accident Cover</h2>
                  </div>
                  <p className="description mt-4" >
                    Some vehicle accidents cause severe damages and may be fatal. Your vehicle insurance covers only your vehicle and third party lives and properties. For your own safety, you need to have a Personal Accident Cover.</p>
               <p className="description mt-4" >
                    It is mandatory to have a Personal Accident Policy along with vehicle insurance. The insurance value of the policy would be 15 lakh rupees.</p>

                </div>

              </div>


            </div>

          </div>

        )}
      </Scrollspy>
      <ScrollToTop />
     <div className='mt-md-5 mt-4'> <Footer  /></div>
    </div>
  )
}

export default Motor_Insurance;

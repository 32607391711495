


// import React, { useState } from "react";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../../../CSS/Navbars.css";
// import { IoIosCall } from "react-icons/io";
// import Logo from "../../../Assets/Img/Logo/logo.png";
// import { Link, useNavigate } from "react-router-dom";
// import { useScrollDirection } from "../../Hooks/useScrollDirection";
// // import NavModal from "../../../NavModal/ NavModal";
// import Button from "react-bootstrap/Button";
// import { IoLogoWhatsapp } from 'react-icons/io';
// import Modal from "react-bootstrap/Modal";
// import { IoMdCall } from "react-icons/io";
// // import { AiOutlineWhatsApp } from "react-icons/ai";
// import ModalImg from "../../../Assets/Img/Headimg/ Modalimage.jpg";
// import { Row, Col, Container } from "react-bootstrap";
// import Card from "react-bootstrap/Card";
// import ModalShare from "../../../ModalShare/ModalShare";
// function Navbars() {
//     const scrollDirection = useScrollDirection();
//     let navigate = useNavigate();
//     const [show, setShow] = useState(false);
//     const [insurance, setInsurance] = useState(false);

//     const showDropdown = (e) => {
//         setShow(!show);
//     };
//     const hideDropdown = (e) => {
//         setShow(false);
//     };

//     const showCheck = (e) => {
//         setInsurance(!insurance);
//     };
//     const hideCheck = (e) => {
//         setInsurance(false);
//     };

//     // const  [cal, setcal] =useState(false)
//     // const showCal = (e) => {
//     //     setcal(!insurance);
//     // }
//     // const hideCal = e => {
//     //     setcal(false);
//     // }

//     const [career, setCareer] = useState(false);
//     const showCareer = (e) => {
//         setCareer(!career);
//     };
//     const hideCareer = (e) => {
//         setCareer(false);
//     };

//     const navItems = [
//         "Know More",
//         "Career in Insurance",
//         "Insurance 1o1",
//         "Speak to Expert",
//     ];
//     const handleClick = (item) => {
//         // switch (item) {
//         //     case 'Know More':
//         //     // return navigate("/about");
//         //     case 'Career in Insurance':
//         //     // return navigate('/terms-condition');
//         //     case 'Case Studies':
//         //     // return navigate('/services');
//         //     case 'Gallery':
//         //     // return navigate('/gallary');
//         //     default:
//         //         return null;
//         // }
//     };

//     const handleHome = () => {
//         return navigate("/home");
//     };

//     const handlejourney = () => {
//         return navigate("/crest-journey");
//     };

//     const handleFaq = () => {
//         navigate("/necessary-question");
//     };

//     const [showw, setShoww] = useState(false);
//     const handleClose = () => setShoww(false);
//     const handleShoww = () => setShoww(true);
//     return (
//         <>
//             {["lg"].map((expand) => (
//                 <Navbar
//                     key={expand}
//                     expand={expand}
//                     className={`${scrollDirection === "down" ? "-top-24" : "top-0"
//                         } navItems sticky-top`}
//                 >
//                     <Container>
//                         <Navbar.Brand onClick={handleHome} className="d-flex">
//                             <img
//                                 src={Logo}
//                                 alt=""
                                
                     
//                                 className="navLogo"
//                             ></img>
                           
//                         </Navbar.Brand>
//                         <Navbar.Toggle
//                             aria-controls={`offcanvasNavbar-expand-${expand}`}
//                             className=""
//                         />
//                         <Navbar.Offcanvas
//                             id={`offcanvasNavbar-expand-${expand}`}
//                             aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
//                             placement="end"
//                             className=""
//                         >
//                             <Offcanvas.Header closeButton></Offcanvas.Header>
//                             <Offcanvas.Body>
//                                 <Nav className="justify-content-end flex-grow-1 pe-4 ms-2">
//                                     {navItems.map((item, index) => {
//                                         return (
//                                             <div>
//                                                 {item === "Speak to Expert" ? (
//                                                     <button
//                                                         className="Contact-Us-btn ms-2 my-3 my-sm-0 btn-info "
//                                                         onClick={handleShoww}
//                                                         key={index}
//                                                     >
//                                                         <IoIosCall style={{ fontSize: "22px" }} /> Speak to
//                                                         Expert
//                                                     </button>
//                                                 ) : item === "Know More" ? (
//                                                     <div>
//                                                         <NavDropdown
//                                                             title="Know More"
//                                                             id="collasible-nav-dropdown"
//                                                             className="me-4 dropdown"
//                                                             show={show}
//                                                             onMouseEnter={showDropdown}
//                                                             onMouseLeave={hideDropdown}
//                                                         >
//                                                             <div className="">
//                                                                 <NavDropdown.Item
//                                                                     className="pb-2"
//                                                                     onClick={handlejourney}
//                                                                 >
//                                                                     Crest The Journey{" "}
//                                                                 </NavDropdown.Item>
//                                                                 <NavDropdown.Item onClick={handleFaq}>
//                                                                     {/* {/ <a href={`section-3`}>FAQ</a> /} */}
//                                                                     FAQ
//                                                                 </NavDropdown.Item>
//                                                             </div>
//                                                         </NavDropdown>
//                                                     </div>
//                                                 ) : item === "Career in Insurance" ? (
//                                                     <div>
//                                                         <NavDropdown
//                                                             title="Career in Insurance"
//                                                             id="carreer"
//                                                             className="me-4"
//                                                             show={career}
//                                                             onMouseEnter={showCareer}
//                                                             onMouseLeave={hideCareer}
//                                                         >
//                                                             <div className="" onClick={() => handleClick(item)}>
//                                                                 <NavDropdown.Item>
//                                                                     <Link to="/career-in-insurance/entrepreneur">
//                                                                         As an Entrepreneur
//                                                                     </Link>{" "}
//                                                                 </NavDropdown.Item>
//                                                                 <NavDropdown.Item>
//                                                                     <Link to="/career-in-insurance/parttime-work">
//                                                                         Part Time Work
//                                                                     </Link>{" "}
//                                                                 </NavDropdown.Item>

//                                                                 <NavDropdown.Item>
//                                                                     <Link to="/career-in-insurance/pos-advisor">
//                                                                         PoS or Advisor
//                                                                     </Link>
//                                                                 </NavDropdown.Item>
//                                                             </div>
//                                                         </NavDropdown>
//                                                     </div>
//                                                 ) : item === "Insurance 1o1" ? (
//                                                     <div>
//                                                         <NavDropdown
//                                                             title="Insurance 1o1"
//                                                             id="collasible-nav-dropdown"
//                                                             className="me-4"
//                                                             show={insurance}
//                                                             onMouseEnter={showCheck}
//                                                             onMouseLeave={hideCheck}
//                                                         >
//                                                             <div className="">
//                                                                 <NavDropdown.Item>
//                                                                     <Link to="/policies/term-insurance">
//                                                                         Term Insurance{" "}
//                                                                     </Link>
//                                                                 </NavDropdown.Item>
//                                                                 <NavDropdown.Item>
//                                                                     <Link to="/policies/health-insurance">
//                                                                         {" "}
//                                                                         Health Insurance
//                                                                     </Link>
//                                                                 </NavDropdown.Item>
//                                                                 <NavDropdown.Item>
//                                                                     <Link to="/policies/motor-insurance">
//                                                                         Motor Insurance{" "}
//                                                                     </Link>
//                                                                 </NavDropdown.Item>
//                                                                 <NavDropdown.Item>
//                                                                     <Link to="/policies/corporate-insurance">
//                                                                         Corporate Insurance{" "}
//                                                                     </Link>
//                                                                 </NavDropdown.Item>
//                                                             </div>
//                                                         </NavDropdown>
//                                                     </div>
//                                                 ) : (
//                                                     <Nav.Link
//                                                         className="text-white me-4"
//                                                         onClick={() => handleClick(item)}
//                                                     >
//                                                         {item}
//                                                     </Nav.Link>
//                                                 )}
//                                             </div>
//                                         );
//                                     })}
//                                     {/* <NavModal /> */}
//                                 </Nav>
//                             </Offcanvas.Body>
//                         </Navbar.Offcanvas>
//                     </Container>
//                 </Navbar>
//             ))}
//             <div>
//                 <Modal
//                     className=" modal-box "
//                     show={showw}
//                     onHide={handleClose}
//                     animation={false}
//                     size="lg"
//                 >
//                     <div className="ModalDiv">
//                         <div className="firstdiv d-flex     ">
//                             <div className="ImageText   ">
//                                 <div>
//                                     <img src={ModalImg} alt="" width="50%" height="50%" />
//                                 </div>
//                                 <div className="my-2  h-100 w-100 p-4">
//                                     <h4> What is Lorem Ipsum?</h4> this is a long established fact
//                                     that a reader will be distracted by the readable content of a
//                                     page when looking at its layout. firstdiv
//                                 </div>
//                             </div>
//                             <div>
//                                 <Button
//                                     variant="light"
//                                     className="btn-close btn1  "
//                                     aria-label="close"
//                                     onClick={handleClose}
//                                 ></Button>
//                             </div>
//                         </div>
//                         <Row className="modalCardsRow">
//                             {" "}
//                             <Col className="my-2">
//                                 <Card className="">
//                                     <Card.Body>
//                                         <Card.Title>Card Title</Card.Title>
//                                         <Card.Text>
//                                             Some quick example text to build on the card title and
//                                             make up the bulk of the card's content.
//                                         </Card.Text>

//                                         <Button variant="primary">
//                                             {" "}
//                                             <IoMdCall /> Call us
//                                         </Button>
//                                     </Card.Body>
//                                 </Card>
//                             </Col>
//                             <Col className="my-2 ">
//                                 <Card>
//                                     <Card.Body>
//                                         <Card.Title>Card Title</Card.Title>
//                                         <Card.Text>
//                                             Some quick example text to build on the card title and
//                                             make up the bulk of the card's content.
//                                         </Card.Text>

//                                         <div><a target='_blank' href="https://wa.me/919911560303"><button className="btn btn-success ms-1  text-white rounded-2" ><IoLogoWhatsapp size={25} className='pb-1' /> Whatsapp Us</button></a></div>
//                                         {/* <div className="me-1" >< ModalShare className=""/></div> */}
//                                     </Card.Body>
//                                 </Card>
//                             </Col>
//                         </Row>
//                     </div>
//                 </Modal>
//             </div>
//         </>
//     );
// }

// export default Navbars;

// // :item== 'Calculator' ?
// // <div>
// //     <NavDropdown title="Later"
// //         id=""
// //         className='me-4'
// //         show={cal}
// //         onMouseEnter={showCal}
// //         onMouseLeave={hideCal}
// //     >
// //         <div className='' onClick={() => handleClick(item)}>
// //             <NavDropdown.Item href="#action3">Crest The Journey </NavDropdown.Item>
// //             <NavDropdown.Item href="#action4">
// //               Health Insurance
// //             </NavDropdown.Item>

// //             <NavDropdown.Item href="#action5">
// //                FAQ
// //             </NavDropdown.Item>

// //         </div>

// //     </NavDropdown>

// // </div>



import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../CSS/Navbars.css";
import Logo from "../../../Assets/Img/Logo/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useScrollDirection } from "../../Hooks/useScrollDirection";
import { Container } from "react-bootstrap";
import ModalInHome from "../../Home-Banner/ModalInHome";

function Navbars() {
  const scrollDirection = useScrollDirection();
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [insurance, setInsurance] = useState(false);

  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  const showCheck = (e) => {
    setInsurance(!insurance);
  };
  const hideCheck = (e) => {
    setInsurance(false);
  };

  const [career, setCareer] = useState(false);
  const showCareer = (e) => {
    setCareer(!career);
  };
  const hideCareer = (e) => {
    setCareer(false);
  };

  const navItems = [
    "Know More",
    "Career in Insurance",
    "Insurance 1o1",
    "Speak to Expert",
  ];
  const handleClick = (item) => {};

  const handleHome = () => {
    return navigate("/home");
  };

  const handlejourney = () => {
    return navigate("/crest-journey");
  };

  const handleFaq = () => {
    navigate("/necessary-question");
  };

  return (
    <>
      {["lg"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className={`${
            scrollDirection === "down" ? "-top-24" : "top-0"
          } navItems sticky-top`}
        >
          <Container>
            <Navbar.Brand onClick={handleHome} className="d-flex">
              <img src={Logo} alt="" className="navLogo"></img>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              className=""
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className=""
            >
              <Offcanvas.Header closeButton></Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-4 ms-2">
                  {navItems.map((item, index) => {
                    return (
                      <div>
                        {item === "Speak to Expert" ? (
                          <ModalInHome />
                        ) : item === "Know More" ? (
                          <div>
                            <NavDropdown
                              title="Know More"
                              id="collasible-nav-dropdown"
                              className="me-4 dropdown"
                              show={show}
                              onMouseEnter={showDropdown}
                              onMouseLeave={hideDropdown}
                            >
                              <div className="">
                                <NavDropdown.Item
                                  className="pb-2"
                                  onClick={handlejourney}
                                >
                                  Crest The Journey{" "}
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={handleFaq}>
                                 
                                  FAQ
                                </NavDropdown.Item>
                              </div>
                            </NavDropdown>
                          </div>
                        ) : item === "Career in Insurance" ? (
                          <div>
                            <NavDropdown
                              title="Career in Insurance"
                              id="carreer"
                              className="me-4"
                              show={career}
                              onMouseEnter={showCareer}
                              onMouseLeave={hideCareer}
                            >
                              <div
                                className=""
                                onClick={() => handleClick(item)}
                              >
                                <NavDropdown.Item>
                                  <Link to="/career-in-insurance/entrepreneur">
                                    As an Entrepreneur
                                  </Link>{" "}
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                  <Link to="/career-in-insurance/parttime-work">
                                    Part Time Work
                                  </Link>{" "}
                                </NavDropdown.Item>

                                <NavDropdown.Item>
                                  <Link to="/career-in-insurance/pos-advisor">
                                    PoS or Advisor
                                  </Link>
                                </NavDropdown.Item>
                              </div>
                            </NavDropdown>
                          </div>
                        ) : item === "Insurance 1o1" ? (
                          <div>
                            <NavDropdown
                              title="Insurance 1o1"
                              id="collasible-nav-dropdown"
                              className="me-4"
                              show={insurance}
                              onMouseEnter={showCheck}
                              onMouseLeave={hideCheck}
                            >
                              <div className="">
                                <NavDropdown.Item>
                                  <Link to="/policies/term-insurance">
                                    Term Insurance{" "}
                                  </Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                  <Link to="/policies/health-insurance">
                                    {" "}
                                    Health Insurance
                                  </Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                  <Link to="/policies/motor-insurance">
                                    Motor Insurance{" "}
                                  </Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                  <Link to="/policies/corporate-insurance">
                                    Corporate Insurance{" "}
                                  </Link>
                                </NavDropdown.Item>
                              </div>
                            </NavDropdown>
                          </div>
                        ) : (
                          <Nav.Link
                            className="text-white me-4"
                            onClick={() => handleClick(item)}
                          >
                            {item}
                          </Nav.Link>
                        )}
                      </div>
                    );
                  })}
                
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Navbars;

import React from 'react';
import Card from 'react-bootstrap/Card';
import '../../CSS/Helpful.css';
import ReactPlayer from 'react-player';
import ReactReadMoreReadLess from "react-read-more-read-less";
import {WhatsappShareButton, FacebookShareButton, TwitterShareButton, LinkedinShareButton,  EmailShareButton} from 'react-share';
import {WhatsappIcon, FacebookIcon, TwitterIcon, LinkedinIcon, EmailIcon} from 'react-share';


const VideoCard = ({details}) => {
  return (
<>

      <Card className='p-1 mx-3 rounded' style={{maxWidth:'100%', height: '100%'}}>
        <ReactPlayer variant="top" controls={true} width='100%' height='242px' url={details.video} />
        <Card.Body>
          <Card.Title className='text-justify m-0'>{details.title}</Card.Title>
          
        </Card.Body>
        <Card.Footer className='bg-white'>
          <ReactReadMoreReadLess className='text-justify' charLimit={100}>
           {details.description}
          </ReactReadMoreReadLess>
          <div className='text-center'>
        <WhatsappShareButton url={details.video} title={details.title}>
            <WhatsappIcon size={30} className='m-2' logofillcolor='white' round={true}></WhatsappIcon>
          </WhatsappShareButton>
          <FacebookShareButton url={details.video} title={details.title}>
            <FacebookIcon size={30} className='m-2' logofillcolor='white' round={true}></FacebookIcon>
          </FacebookShareButton>
          <TwitterShareButton url={details.video} title={details.title}>
            <TwitterIcon size={30} className='m-2' logofillcolor='white' round={true}></TwitterIcon>
          </TwitterShareButton>
          <LinkedinShareButton url={details.video} title={details.title}>
            <LinkedinIcon size={30} className='m-2' logofillcolor='white' round={true}></LinkedinIcon>
          </LinkedinShareButton>
          <EmailShareButton url={details.video} title={details.title}>
            <EmailIcon size={30} className='m-2' logofillcolor='white' round={true}></EmailIcon>
          </EmailShareButton>
          </div>
          </Card.Footer>
      </Card>

  </>
   );
}

export default VideoCard;

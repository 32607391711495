import React from 'react'
import { Button, Container } from 'react-bootstrap'
import Faq_accordian from '../Common/FaqAccordian/FaqAccordian'
import '../../CSS/Faq.css'
import { useNavigate } from 'react-router-dom'

export const MOCK_FAQ_VALUES = [
  {
    id: 1,
    title: 'What is Travel Insurance?',
    body: ' A travel insurance is the insurance that protects you and your family when you are out for vacation overseas or out of the station. it covers the expense of medical treatment or in case of an accident. other losses such as loss of personal documents baggage or essentials are also covered under this policy. it is the best option for people traveling abroad as it covers many uncertainties that can arise in a foreign land which can be difficult to manage at times.'
  },
  {
    id: 2,
    title: 'What benefits do I get in Health Insurance?',
    body: 'Health insurance covers one from hospitalization expenses additionally it carries various other benefits such as an increase in the SI via the no claim bonus, reinstatement benefit, Tax benefit under 80C/80D and most importantly it reduces burden of expenses from the shoulders of the insured.'
  },
  {
    id: 3,
    title: 'Why is Health Insurance Important?',
    body: 'Every individual needs to buy health insurance according to their needs. Moreover, health insurance provides monetary relief for critical illnesses and cannot be ignored at all costs.'
  },
  {
    id: 4,
    title: 'What is a Health Insurance?',
    body: ' A health insurance policy is a contract between an insurer and an individual/group in which the insurer agrees to provide specified health insurance cover at a premium.'
  },
  {
    id: 5,
    title: 'What is a critical illness rider is life insurance?',
    body: 'A critical illness rider is an additional cover that helps the policyholder financially in the case they are diagnosed with a critical illness during their policy tenure. It is a life threatening disease such as cancer. The benefit received thus can be used for further treatment of such diseases.  '
  },
  {
    id: 6,
    title: 'Which is better: ULIP or Traditional?',
    body: 'Both of these plans aim at serving two different purposes one is capital appreciation and another one is protection. ULIP plans are however market-oriented and thus are more beneficial and lucrative as these are handled by professional fund managers and strategic professionals thus they tend to be more likable due to a decent rate of interest and CAGR.'
  },
]

const Faq = () => {

  let navigate =useNavigate();

  const handleClick = () => { 
    navigate ('/necessary-question')
  }

  return (
    <div className='mb-5' >
    <div className='text-center my-5' ><h2>FAQ</h2></div>
      <Container>
        <div className='row'>
          {MOCK_FAQ_VALUES.map((value, index) => {
            return (
              <div className='col-12 col-md-6 mb-4' key={index}>
                <Faq_accordian details={value}/>
              </div>
            )
          })}
        </div>
        <div className='mt-3' style={{textAlign:'center'}}>
          <Button   className='readbtn  style' href={`#section-1`} onClick={handleClick}>Read More</Button>
        </div>
      </Container>
    </div>
  )
}

export default Faq;

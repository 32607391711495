import React from 'react'

import Footer from '../../Components/Common/Footer/Footer'
import Navbars from '../../Components/Common/Navbar/Navbars'
import Behind_crest from '../../Components/PeopleBehindCrest/PeopleBehindCrest'
import ScrollToTop from '../../Components/ScrollControl/ScrollControl'
import Tabs from '../../Components/Tabs/Tabs'

const WithCrest = () => {
  return (
    <>
    <Navbars/>
    <Tabs/>
    <Behind_crest/>
    <ScrollToTop/>
    <Footer/>
      
    </>
  )
}

export default WithCrest;

import React, { useRef } from 'react'
import Footer from '../../Components/Common/Footer/Footer'
import Navbars from '../../Components/Common/Navbar/Navbars'
import PoliciesTabs from '../../Components/PoliciesTabs/PoliciesTabs'
import { Scrollspy } from "@makotot/ghostui";
import ScrollToTop from '../../Components/ScrollControl/ScrollControl';
import { Row, Col, Card } from 'react-bootstrap';


const Health_Insurance = () => {

  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];


  return (
    <div>
      <Navbars />
      <PoliciesTabs />
      <div>       <h1 className="text-center mt-4">Health Insurance</h1>
        <Scrollspy sectionRefs={sectionRefs}>
          {({ currentElementIndexInViewport }) => (
            <div className='d-md-flex '>
              <div className=" position-md-sticky top-5 " style={{ width: '300px' }}>
                <div className="  position-sticky t-v pt-md-5 pt-2" style={{ width: '300px' }} >
                  <ul
                    data-cy="nav-wrapper"
                    className="space-y-5 cursor-pointer mt-12"
                  >
                    <li
                      className={
                        currentElementIndexInViewport === 1
                          ? "is-active"
                          : ""
                      }
                    >
                      <a href={`#section-1`}>What is Health Insurance</a>
                    </li>
                    <li
                      className={
                        currentElementIndexInViewport === 2
                          ? "is-active"
                          : ""
                      }
                    >
                      <a href={`#section-2`}>Why must you take Health Insurance</a>
                    </li>
                    <li
                      className={
                        currentElementIndexInViewport === 3
                          ? "is-active"
                          : ""
                      }
                    >
                      <a href={`#section-3`}>Health Insurance Check list</a>
                    </li>
                    {/* <li
                    className={
                      currentElementIndexInViewport === 4
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-4`}>Corporate Insurance</a>
                  </li> */}
                    <li
                      className={
                        currentElementIndexInViewport === 5
                          ? "is-active"
                          : ""
                      }
                    >
                      <a href={`#section-5`}>Health Benefits</a>
                    </li>
                    <li
                      className={
                        currentElementIndexInViewport === 6
                          ? "is-active"
                          : ""
                      }
                    >
                      <a href={`#section-6`}>Know Top Up Plans</a>
                    </li>

                   
                  </ul>
                </div>
              </div>
              <div className=" px-md-5 mt-md-4 px-4">
                <div data-cy="section-wrapper" className='px-md-3'>
                  <div
                    id={`section-1`}
                    ref={sectionRefs[1]}
                    className={
                      currentElementIndexInViewport === 1 ? "active" : ""
                    }
                  >
                    <div
                      className="text-center mt-4"
                      id="entrepreneur"
                    >
                      <h2>What is Health Insurance </h2>
                    </div>
                    <div>
                      <p> A healthy mind lives in a healthy body!</p>
                      <p className='ps-5'style={{opacity:'50%'}}><b>- Old Saying</b></p>
                      <p>
                        We all wish to live a healthy life, free of any complications and
                        illness. However, many a times what we wish for, does not
                        necessarily pan out in the same way. We may need to go for
                        hospitalization owing to any medical complications or because of any
                        sudden accident or mishappening.
                      </p>
                      <p>Hospitalization expense surely makes a hole in our pocket and may dent our finances and financial planning.</p>
                      <p>Imagine a friend who comes to our rescue here, helps us and foots all our bills. Would it not make us happy? Surely, yes!</p>
                      <p>Health Insurance is that friend, that helps us meet our expense whenever we get hospitalized.</p>




                    </div>
                  </div>


                  <div
                    id={`section-2`}
                    ref={sectionRefs[2]}
                    className={
                      currentElementIndexInViewport === 2 ? "active" : ""
                    }
                  >
                    <div
                      className=" journey-title text-center mb-1 mt-5"
                      id="parttime"
                    >
                      <h2>Why must you take Health Insurance</h2>
                    </div>
                    <p>Age old wisdom to create wealth says - Maximise your savings and minimise your spending!</p>
                    <p>Hospitalization does exactly opposite and hence is a sure shot wealth destroyer. Hospitalization always brings a double whammy for a person – (a) he/she has to pay for a heavy hospitalization bill; and (b) income gets reduced because of long hospitalization.</p>
                    <p>Hence, Health insurance becomes effective tool for Wealth planning</p>
                    <p>It is a fact that more than 50% of Indian population pay for their hospitalization expenses from their own pocket. While we do not pray for it, Health and accidental emergency is a greater possibility in today’s lifestyle. Even one Hospitalization can prove to be very costly and troublesome affair for many families!</p>
                    <p>It is therefore, a common sensical approach to be ready for such eventuality and get a Health Insurance policy. It doesn't cost that huge!</p>
                    <p>Besides, Wealth planning and common sense, there's other incentive of Income Tax Saving under section 80 (D), if one takes a Health Insurance Policy</p>
                    <div className="journey-simple">

                    </div>
                  </div>

                  <div
                    id={`section-3`}
                    ref={sectionRefs[3]}
                    className={
                      currentElementIndexInViewport === 3 ? "active" : ""
                    }
                  >
                    <div
                      className="journey-title text-center mb-1 mt-md-5 mt-4"
                      id="fulltime"
                    >
                      <h2>Health Insurance Check list</h2>
                    </div>

                    <p>Best policy is one that suits your requirement and your pocket!</p>
                    <p className='ps-5' style={{opacity:'50%'}}><b>	- Rule of Thumb</b></p>
                    <p>
                      While this rule of thumb is the ultimate guide to buying anything in life     , at Crest we have put our logical thinking to work, to suggest following must check list before buying a Health insurance policy. You may check subsequent sections for their details.
                    </p>

                    <Row className="my-2">
                      <Col md={6}  xs={12} className='my-2'>
                        <Card className=' w-100 h-100 p-2'>
                          <div className="text-center my-2"><h4>No or Minimum Co Pay</h4>
                            <p>Never split the bills with insurance company for your hospitalization expense. No or minimum co-pay must be preferred</p>
                          </div>
                        </Card>
                      </Col>
                      <Col md={6} xs={12} className='my-2'>
                        <Card className=' w-100 h-100 p-2'>
                          <div className="text-center my-2"><h4>Limits or Restrictions</h4>
                            <p>Do not opt for any limits in policy like, room type and rent capping, sub-limits of disease, capping on incidental services, etc.</p>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}  xs={12} className='my-2'>
                        <Card className=' w-100 h-100 p-2'>
                          <div className="text-center my-2"><h4>Restoration Benefit</h4>
                            <p>Insurer restores your covers back to original, in case the cover amount gets exhausted during policy period</p>
                          </div>
                        </Card>
                      </Col>
                      <Col md={6}  xs={12} className='my-2'>
                        <Card className=' w-100 h-100 p-2'>
                          <div className="text-center my-2"><h4>Pre & Post Hospitalization Care</h4>
                            <p>Care is needed before and after treatment as well. hence, choose a policy that provides cover pre & post hospitalization</p>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6} xs={12} className='my-2'>
                        <Card className=' w-100 h-100 p-2'>
                          <div className="text-center my-2"><h4>Less Waiting period</h4>
                            <p>Health Insurance covers pre-exiting and specified diseases after some time. Hence take a policy that has least waiting period.</p>
                          </div>
                        </Card>
                      </Col>
                      <Col md={6}  xs={12} className='my-2'>
                        <Card className=' w-100 h-100 p-2'>
                          <div className="text-center my-2"><h4>Health & Other benefits</h4>
                            <p>Care is needed before and after treatment as well. hence, choose a policy that provides cover pre & post hospitalization</p>
                          </div>
                        </Card>
                      </Col>
                    </Row>     <p>Didn’t we say that we are here to make things Simple, Un-biased and Scientific</p>
                    <p ><b><u>No or minimum co-pay</u></b></p>
                    <p>Co-pay simply means, splitting the bills. It may cost you less at the time of buying, but in return you are agreeing to split the bill of your hospitalization expenses, that you may incur.</p>
                    <p>Now, no one likes to split the bill that to something that would run in probably lakhs of rupees for saving few hundred or may be thousand now</p>
                    <p>It is like ~~ Penny wise, Pound foolish!</p>
                    <p>Opt for this, only when there is no other alternate available, like in case of some senior citizen or specific disease plans</p>
              
                    <p ><b><u>Limits or Restrictions</u></b></p>
                    <p>Some insurers put a condition on type of rooms, capping on hospital room rent, sub-limit for various diseases, fixed amount for incidental activities, like – Ambulance charges, Donor charges, etc.</p>
                    <p>Remember, more the limits, capping or restrictions, less is the flexibility at the time of hospitalization. Further, more restrictions also mean that you end up paying more from your pocket at the time of any hospitalization.</p>
                    <p>Go for a policy with minimum limits, capping or restrictions.</p>
            
                    <p ><b><u>Restoration Benefits</u></b></p>
                    <p>What if you have a magic pot that never gets empty, feeding unlimited number of people!</p>
                    <p>Restoration is one such magic pot. It restores your cover amount back to original in case it gets exhausted during the policy period.</p>
                    <p>Let us understand this with an example. Suppose you have a cover of 10 lakh rupees and during one or multiple hospitalizations it gets exhausted. Now, next time, another family member, who is part of the same policy, get hospitalized. Since, the insurance amount has been exhausted, to ensure that the family member does not suffer, insurer restores your cover back to original, that is to 10 lakhs</p>
                    <p>
                      Remember, not every Health insurance offers restoration. Further, various insurers offer different type of restoration, like restoration not available for same person or for same disease. You should select the Health insurance policy that offers restoration with maximum option
                    </p>
                    <p>
                      Let us understand this with an example. Suppose you have a cover of 10 lakh rupees and during one or multiple hospitalizations it gets exhausted. Now, next time, another family member, who is part of the same policy, get hospitalized. Since, the insurance amount has been exhausted, to ensure that the family member does not suffer, insurer restores your cover back to original, that is to 10 lakhs
                    </p>
                    <p>Remember, not every Health insurance offers restoration. Further, various insurers offer different type of restoration, like restoration not available for same person or for same disease. You should select the Health insurance policy that offers restoration with maximum option</p>
              
                    <p ><b><u>Pre and Post Hospitalization care</u></b></p>
                    <p>Generally, our hospitalization does not happen automatically. It requires a lot of visit and follow up with doctors in a lead up to hospitalization and then again a follow up after hospitalization to get completely cured.</p>
                    <p>If pre-hospitalization consultation and post hospitalization follow up are a routine, why should your Health Insurance policy not cover them.</p>
                    <p>Fact of the matter is, most of the Health Insurance policies cover pre and post hospitalization care and expenses.</p>
                    <p>We advise to choose a policy that offers the maximum pre and post hospitalization cover.</p>
              
                    <p ><b><u> Less Waiting Period</u></b></p>
                    <p> Wouldn’t it be amazing that your pre-existing diseases also get covered in the Health Insurance policy you take?</p>

                    <p> Good news is that most Health Insurers cover pre-existing diseases. However, there is a waiting time of 12 to 48 months for them to be covered.</p>

                    <p> Further, there is a list of specified diseases that also gets covered after the waiting period ends.</p>

                    <p> It is a good idea to take such health insurance, where you do not have to wait for too long to avail covers for such diseases. </p>


                  </div>



                  <div
                    id={`section-5`}
                    ref={sectionRefs[5]}
                    className={
                      currentElementIndexInViewport === 5 ? "active" : ""
                    }
                  >
                    <div
                      className="journey-title text-center mb-1 mt-4 mt-md-5"
                      id="ds"
                    >
                      <h2> Health Benefits</h2>
                    </div>

                    <p > Everybody wants that something extra</p>
                    <p className='ps-5' style={{opacity:'50%'}}><b>	- icing on cake moment</b></p>

                    <p> Insurers provide some extra benefits to customers. Some benefits are provided to loyal and profitable customers, while others are for every customer. These should not be the primary criteria to decide an insurance policy, still maximizing benefits is always a good idea.</p>

                    <p> Some of the additional benefits like can be, as follows:</p>
            
                    <p ><b><u>Free Health Check up</u></b></p>
                    <p> Insurer pay for your health check-up, generally once a year.</p>

                    <p> So, your routine annual check-up expense is now borne by the insurer </p>

   
                    <p ><b><u> Bonus or Additional Cover amount for making no claim</u></b></p>
                    <p> In case you do not make any claim in a policy year, some insurers offer you additional cover amount. </p>

                    <p> For example, your existing cover of 10 lakh can become 11 lakh free of cost, if you do not make any claim and the insurer offers you a 10% No Claim Bonus</p>

                    <p> It would certainly be pleasing to get additional cover for making no claims </p>

                
                    <p ><b><u>Domiciliary Treatment Facilities</u></b></p>
                    <p> Certain treatments are administered at home under proper supervision of nursing and doctors’ team. Many insurers provide for such expenses incurred at home </p>

                    <p> It would be a good idea to have such feature in our policy </p>
        
                    <p ><b><u>Alternative Treatment like Unani, Homoeopathy, etc</u></b></p>
                    <p> Certain people prefer the other forms of treatments, like Unani, Ayurveda, Homeopathy, etc.</p>
                    <p> Many insurers have started covering these forms of treatments as well. </p>
                  </div>


                  <div
                    id={`section-6`}
                    ref={sectionRefs[6]}
                    className={
                      currentElementIndexInViewport === 6 ? "active" : ""
                    }
                  >
                    <div
                      className="journey-title text-center mb-1 mt-4 mt-md-5"
                      id="ds"
                    >
                      <h2> Know Top Up Plans </h2>
                    </div>
                    <p> Remember that upgrade in hotels or aeroplanes or clubs whereby just paying a very nominal fee you get an upgrade to the next level with much larger rewards and choices. Of course! To get an upgrade, you need to have the basic booking done</p>

                    <p> A Top Up in Health Insurance is like that Upgrade. If you have a basic plan with you, you can take a very large insurance cover for literally a fraction of the cost of original policy</p>

                    <p> Top Up in Health Insurance serves 2 purposes: </p>
                    <p ><b><u>Enhances your covers multiple times</u></b></p>
                    <p> For example, for a base cover of 10 lakh, you can take a top up of 90 lakh just by paying couple of thousand extra. So now you have a total cover of 1 crore, instead of 10 lakh</p>

               
                    <p ><b><u>Does not hurt your pocket</u></b></p>
                    <p> Top Up policy works on law of averages and hence they cost a miniscule amount as compared to the base policy. Taking a Top Up policy hence costs you practically nothing!</p>

                    <p> It is a good idea to have Top Up policies added in your Base plan!</p>


                  </div>












                </div>


              </div>

            </div>

          )}
        </Scrollspy>
      </div>
      <ScrollToTop />
      <Footer />
    </div>
  )
}

export default Health_Insurance;

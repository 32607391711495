import React from 'react';
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from '../../Assets/Img/demo.png';
import '../../CSS/Career.css';
import {Link} from 'react-router-dom'
const Career = () => {

  return (
    <>
      <Container className='my-3'>
        <Row>
          <Col sm={12} md={4} lg={3} className="text-center">
            <img className='imgOne' src={Image} width='200px' height='200px' alt="" />
          </Col>
          <Col sm={12} md={8} lg={9} className="demoText">
            <h2 className='head mt-3'>Careers In Insurance</h2>
            <p>
              Wouldn't it feel awesome to help protect someone and get rewarded for the same. Would you mind making money advising people in their financial plan and securing their dreams by buying protection
            </p>
            <p>Besides it could be really flexible to choose how much time you work -<b>part time or full time</b></p>
            <div className='btnOne '><Button  className="style" ><Link to='/career-in-insurance/entrepreneur' className='btnTwo text-white'>Know More</Link></Button></div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Career;

import React from 'react'
import { Navbar } from 'react-bootstrap'
import AllFaq from '../../Components/AllFaq/AllFaq'
import Footer from '../../Components/Common/Footer/Footer'
import Navbars from '../../Components/Common/Navbar/Navbars'
import ScrollToTop from '../../Components/ScrollControl/ScrollControl'

const Neceassary = () => {
  return (
    <div>
      <Navbars />
      <AllFaq />
      <ScrollToTop/>
      <Footer />

    </div>
  )
}

export default Neceassary;

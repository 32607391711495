import React from 'react';
import { CardGroup, Col, Container, Row } from 'react-bootstrap';
import VideoCard from '../CommonVideocard/Videocard';



const Hdata = [
    {
        id: 1,
        video: "https://www.youtube.com/shorts/4H-B5bJebBI",
        title: "Launching Crest Insure - Simplifying Insurance",
        description: ""
    },
    
    {
        id: 2,
        video: "https://www.youtube.com/watch?v=lYQ4DMyt0lc",
        title: "Trust Crest. Get Insured.",
        description: "We can never predict what life throws at us. #TrustCrest and get insured to protect you and your loved ones."
    },
    
    {
        id: 3,
        video: "https://www.youtube.com/watch?v=ABkoOgjsA00",
        title: "Crest Insure",
        description: " Our founder speaks on what's in offing"
    }
]

const HelpVideo = () => {
  return (
    <>
    <Container>
   <Row>
   <h2 className='text-center my-4'>Helpful Videos</h2>
<CardGroup className='cardg'>

    {Hdata.map((values,index)=>{
        
        return(
<Col xs={12} sm={6} lg={4} key={index} className='my-2'>

            <VideoCard details={values}/>
</Col>
);
        
    })}
    </CardGroup>
    </Row>
</Container>
</>
  );
}

export default HelpVideo;




import React from 'react'
import Footer from '../../Components/Common/Footer/Footer'
import Navbars from '../../Components/Common/Navbar/Navbars'
import Tabs from '../../Components/Tabs/Tabs'
import TermsUsage from '../../Components/TermsUsage/Usage'
import Scrolltop from '../../Components/ScrollControl/ScrollControl'
const TermsCondition = () => {
    return (
        <div>
            <Navbars />
            <Tabs/>
            <TermsUsage />
            <Scrolltop/>
            <Footer />
        </div>
    )
}

export default TermsCondition;


import React, { useRef } from 'react'
import { Scrollspy } from "@makotot/ghostui";
import '../../CSS/CrestJourney.css'

import Navbars from '../../Components/Common/Navbar/Navbars';
import Footer from '../../Components/Common/Footer/Footer';
import ScrollToTop from '../../Components/ScrollControl/ScrollControl';

const Policies = () => {

    const sectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
      ];


  return (
    <div>
    <Navbars/>
     <Scrollspy sectionRefs={sectionRefs}>
        {({ currentElementIndexInViewport }) => (
          <div className='d-md-flex '>
            <div className=" position-md-sticky top-5 " style={{ width: '320px' }}>
              <div className="  position-sticky t-v  mt-md-5 pt-md-5 pt-2" style={{ width: '220px' }} >
                <ul
                  data-cy="nav-wrapper"
                  className="space-y-5 cursor-pointer mt-12"
                >
                  <li
                    className={
                      currentElementIndexInViewport === 1
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-1`}>Health Insurance</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 2
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-2`}>Life Insurance</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 3
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-3`}>Motor Insurance</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 4
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-4`}>Corporate Insurance</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className=" px-md-5 px-4">
              <div data-cy="section-wrapper" className='px-md-3'>
                <div
                  id={`section-1`}
                  ref={sectionRefs[1]}
                  className={
                    currentElementIndexInViewport === 1 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center"
                    id="entrepreneur"
                  >
                   Health Insurance
                  </div>
                  {/* <p className=" journey-Subtitle leading-relaxed text-lg caps text-gray-800  mb-4 text-center">
                    <span><i> Bridging the Indian Protection Gap</i></span>
                  </p> */}

                  <p className="text-md leading-7 mt-4">
                    At Crest Insure, we love all things
                    &quot;insurance&quot;. We believe that the way
                    insuranc products have been distributed, over the
                    years, is not the best, and can be improved
                    tremendously. It has also caused Indians being
                    under-insured
                  </p>
                  <p className="text-md leading-7 mt-4">
                    We understand that India struggles with 4 problems in
                    insurance distribution:
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Concentration of Insirance Activities in Metro &
                    Tier 1 cities (70% business come from few big cities)
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Less Financial Literacy as people still struggle to
                    comprehend insurance properly
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Ad-Hoc and Incentive based insurance buying
                    resulting in improper planning and under insurance
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Unprofessional and Less trained advisors resulting
                    in improper Insurance Journey for buying insurance
                  </p>
                </div>
                <div
                  id={`section-2`}
                  ref={sectionRefs[2]}
                  className={
                    currentElementIndexInViewport === 2 ? "active" : ""
                  }
                >
                  <div
                    className=" journey-title text-center mb-1"
                    id="parttime"
                  >
                   Life Insurance
                  </div>
                  {/* <p className="journey-Subtitle caps leading-relaxed text-lg text-gray-800  mb-4 text-center">
                    <span><i> Simple | Unbiased | Scientific</i></span>
                  </p> */}
                  <div className="journey-simple">
                    Simple
                  </div>
                  <p className="text-md leading-7 mt-4">
                    We believe that the solution to India&apos;s protection gap lies in making insurance and financial
                    planning simple and easy to understand. We have made it simple through our content offerings
                    in the language of your choice and in the way you want - video and blogs.
                  </p>
                  <p className="mt-3">
                    Further, our Assisted Insure-Tech model helps you to navigate things on your own along with the
                    support of trained and professional advisor partners
                  </p>
                  <div className="journey-simple">
                    Scientific
                  </div>
                  <p className="text-md leading-7 mt-4">
                    We also understand that insurance buying and selling should not be ad-hoc affair, nor should it
                    be based on incentives like Tax Deduction, etc. It should be based on pure need analysis and be
                    scientific. For the same we are creating Crest Insure Journey, a scientific yet simple way to assess
                    your need and find out how much and which insurance to buy
                  </p>
                  <div className="journey-simple">
                    Unbiased
                  </div>
                  <p className="text-md leading-7 mt-4">
                    Our trained advisor partners use the journey to esnure that product that best suit your
                    requirments, only gets recommended removing any bias
                  </p>
                </div>

                <div
                  id={`section-3`}
                  ref={sectionRefs[3]}
                  className={
                    currentElementIndexInViewport === 3 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1"
                    id="fulltime"
                  >
                   Motor Insurance
                  </div>
                  {/* <p className=" journey-Subtitle leading-relaxed caps text-lg text-gray-800  mb-4 text-center">
                    <span><i>
                      Journey Calculator | Targetted Distribution |
                      Assisted Insure-Tech
                    </i></span>
                  </p> */}
                  <div className="journey-simple">
                    Journey Calculator
                  </div>
                  <p className="text-md leading-7 mt-4">
                    Phasellus dignissim, lorem vel cursus luctus, turpis
                    massa sollicitudin nisi, eu facilisis tellus nibh eu
                    lacus. Fusce sodales leo id viverra tristique.
                    Pellentesque habitant morbi
                  </p>
                  <div className="journey-simple">
                    Targetted Distribution
                  </div>
                  <p className="text-md leading-7 mt-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Fusce volutpat accumsan volutpat. Sed viverra
                    nisl at consectetur
                  </p>
                  <div className="journey-simple">
                    Assisted Insure-Tech
                  </div>
                  <p className="text-md leading-7 mt-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Fusce volutpat accumsan volutpat. Sed viverra
                    nisl at consectetur
                  </p>
                </div>

                <div
                  id={`section-4`}
                  ref={sectionRefs[4]}
                  className={
                    currentElementIndexInViewport === 4 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1"
                    id="job"
                  >
                     Corporate Insurance 
                  </div>
                  <p className=" journey-Subtitle leading-relaxed caps text-lg text-gray-800  mb-4 text-center">
                    <span><i>Product For Every Indian Customer Need</i></span>
                  </p>
                  <p className="text-md leading-7 mt-4">
                    At Crest Insure, we want to protect every Indian&apos;
                    need. We would offer product in following categories
                    to serve the Indian needs:
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Health Insurance
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Motor Insurance
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Travel Insurance
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Term Insurance
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Investment Products
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Construction and Engineering Insurance
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Fire and Burglary Policies
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Policies for offices, factories and employers
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Policies for professional
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Policies for schools, hospitals and shopping malls
                  </p>
                </div>
               

               
              </div>
             
            </div>
           
          </div>
          
        )}
      </Scrollspy>
      <ScrollToTop/>
    <Footer/>
    </div>
  )
}

export default Policies;

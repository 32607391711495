import React from 'react'
import Card from 'react-bootstrap/Card';
import '../../../CSS/InsuranceCard.css'

const InsuranceCard = ({dettails}) => {
  return (
    <>
     <Card className='px-1 importance-card' style={{ width: '100%', height:"100%", textAlign:'center'}}>
     <div className=''>
      <Card.Img variant="center" className='pt-2' src={dettails.image} width='100px' style={{alignSelf: 'center'}} />
      <Card.Body>
        <Card.Title style={{fontSize:"22px"}}>{dettails.title}</Card.Title>

     <Card.Text className=' ' >
          {dettails.subTitle}
        </Card.Text>

        {/* <Button variant="primary">Go somewhere</Button> */}
      </Card.Body>
      </div>
    </Card>
      
    </>
  )
}

export default InsuranceCard

import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../../CSS/Tab.css'


const Tabs = () => {
  let navigate =useNavigate();

  const handleClick = () => { 
    navigate ('/terms')
  }

  const crestWay =()=>{
    return navigate ('/crest-journey')
  }

  const contactUs =()=>{
    return navigate ('/contact')
  }
  const withCrest =()=>{
    return navigate ('/work-with-crest')
  }

  return (
    <div className='row d-flex justify-content-center my-4 mx-3'>
      
   
       <div onClick={crestWay} className='routes'> The Crest Way</div>
       <div onClick={withCrest} className='routes'> People behind Crest</div>
       <div onClick={contactUs} className='routes'>  Contact Us | Location</div>
       <div onClick={handleClick} className='routes'>  Terms & Conditions</div>
 

    </div>
  )
}

export default Tabs;

import Accordion from 'react-bootstrap/Accordion';

function BasicExample({details}) {
  return (
    <Accordion defaultActiveKey="0" style={{fontSize:'18px',    boxShadow: '3px 3px 6px #ddd, -3px 3px 6px #ddd'}}>
      <Accordion.Item eventKey="1">
        <Accordion.Header style={{fontSize:'18px'}}>{details.title}</Accordion.Header>
        <Accordion.Body style={{fontSize:'18px'}}>
            {details.body}
        </Accordion.Body>
      </Accordion.Item>
     
    </Accordion>
  );
}

export default BasicExample;
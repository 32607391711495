import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import "../../CSS/ReachUs.css";
import ModalShare from "../../ModalShare/ModalShare";
import { IoMdCall } from 'react-icons/io';
import { IoLogoWhatsapp } from 'react-icons/io';

function Reachus() {
  return (
    <div>
      <Container className="mt-4  ">
        <Row>
          <h2 className="text-center"> Reach Us</h2>
          <br />
          <br />
          <p className="text-center">
            Meeting of 2 great minds always creates a great product or service
          </p>
        </Row>
        <br />
        <Row>
          <div className=" text-center col-sm-6  col-lg-3   mt-3 ">
            <h6> <b>E-mail support</b> </h6> <a href="mailto:hello@crestinsure.com">hello@crestinsure.com </a> 
          </div>
          <div className=" col-sm-6  col-lg-3  text-center mt-3 ">
            <h6> <b>IVR Support</b> </h6><a href="tel:+919911560303">  9911560303</a> <br /> Monday to Friday <br />
            10 am to 6 pm
          </div>
          <div className=" text-center col-sm-6  col-lg-3  mt-3  ">
            <h6 className=" "><b>Speak to Expert</b> </h6>
            <div className="button  text-center  ">
           
              <div> <a href="tel:+919911560303"><button className="btn btn-info style mx-1 my-1  text-white rounded-2" ><IoMdCall size={25} className='pb-1'/> Call Us</button></a></div>
 
              <div><a target='_blank' href="https://wa.me/919911560303"><button className="btn btn-success mx-1 my-1 text-white rounded-2" ><IoLogoWhatsapp size={25} className='pb-1' /> Whatsapp Us</button></a></div>
              {/* <div className=" my-1 mx-1" >< ModalShare className=""/></div> */}
            </div>
          </div>
          <div className=" col-sm-6 col-lg-3  text-center  mt-3 ">
            <b>© Crest Insure</b> <br />
            CREST INSURANCE BROKERS PRIVATE LIMITED <br />
            REG. ADDRESS:
                            PLOT-65, FIRST FLOOR, LANDMARK HOUSE, SECTOR 44, GURGAON, HARYANA - 122003
            <br />
            License category- Direct Broker (Life & General) <br />
            IRDA  Registration No. 841, Registration Code No. IRDA/ DB 935/ 2022, Valid till 02/08/2025<br />
           
            <div></div>
          </div>
        </Row>
        <Row>
          <div className=" col-sm-12  col-lg-12   mt-4 text-center ">
            <p>
            IRDA  Registration No. 841, Registration Code No. IRDA/ DB 935/ 2022, Valid till 02/08/2025 <br />
           
              
            </p>
          </div>

          <div className="container">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7015.891699485988!2d77.0681643!3d28.4510487!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19614c66d1db%3A0xd20f2f8e508ef42!2sCrest%20Insurance%20Brokers%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1663941472348!5m2!1sen!2sin" width="100%" height="300px"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </Row>
      </Container>
    </div>
  );
}
export default Reachus;

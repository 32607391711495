import React from 'react'
// import Insurance from './Components/Insurance-Importance/Insurance'
import Home from './Container/Home/Home';
import ContactUs from './Container/ContactUs/Contact';
import '../src/App.css'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TheCrestJourney from './Container/TheCrestJourney/TheCerstJourney';
import TermsCondition from './Container/Terms&Condition/TermsCondition';
import WithCrest from './Container/WorkWithCrest/WorkWithCrest';
import Policies from './Container/Policies/Policies';
import Neceassary from './Container/NeceassaryQuestions/Neceassary';
import Health_Insurance from './Container/Policies/HealthInsurance';
import Motor_Insurance from './Container/Policies/MotorInsurance';
import Term_Insurance from './Container/Policies/TermInsurance';
import Corporate_Insurance from './Container/Policies/CorporateInsurance';
import AnEntrepreneur from './Container/CareerInInsurance/AnEntrepreneur';
import PartTimeWork from './Container/CareerInInsurance/PartTimeWork';
import Pos_advisor from './Container/CareerInInsurance/PosAdvisor';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';


const App = () => {
  return (
    <div className='app-font'>
      <BrowserRouter>

        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/crest-journey" element={<TheCrestJourney/>} />
          <Route path="/terms" element={<TermsCondition/>} />
          <Route path="/work-with-crest" element={<WithCrest/>} />
          <Route path="/contact" element={<ContactUs/>} />
          <Route path="/policies" element={<Policies/>} />
          <Route path="/policies/health-insurance" element={<Health_Insurance/>} />
          <Route path="/policies/motor-insurance" element={<Motor_Insurance/>} />
          <Route path="/policies/term-insurance" element={<Term_Insurance/>} />
          <Route path="/policies/corporate-insurance" element={<Corporate_Insurance/>} />
          <Route path="/necessary-question" element={<Neceassary/>} />
          <Route path="/career-in-insurance/entrepreneur" element={<AnEntrepreneur/>} />
          <Route path="/career-in-insurance/parttime-work" element={<PartTimeWork/>} />
          <Route path="/career-in-insurance/pos-advisor" element={<Pos_advisor/>} />
          
          <Route path="*" element={<h1>Error 404 page not found!!</h1>} />

        </Routes>

      </BrowserRouter>
      
    </div>
  );
}

export default App;

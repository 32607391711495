import React from 'react'
import InsuranceCard from '../Common/InsuranceCard/InsuranceCard'
import image1 from '../../Assets/Img/Insurance/image1.png';
import image2 from '../../Assets/Img/Insurance/image2.png';
import image3 from '../../Assets/Img/Insurance/image3.png';
import image4 from '../../Assets/Img/Insurance/image4.png';
import { Row,Col, Container } from 'react-bootstrap';
import CardGroup from 'react-bootstrap/CardGroup';

 export const IMPORTANCE_VALUES = [
    {
        image:image1,
        title:'Covers Financial Loss',
        subTitle:'Any financial loss to your vehicles or belongings or due to hospitalization or death can be covered by insurance',
    },
    {
        image:image2,
        title:'Peace of Mind',
        subTitle:'When you are crisis proof, are protected against your liabilities and goals are guaranteed, you would feel great',
    },
    {
        image:image3,
        title:'Guarantees Goal',
        subTitle:'Get curated content in the language of your choice Comfort of knowledge, literally ',
    },
    {
        image:image4,
        title:'Saves Tax',
        subTitle:'Learning modules curated to make career advisors and professional financial coaches Fun and Earn, together',
    }
]

const Importance = () => {
  return (
    <div >
        
        <Container>
        <div >
        <h2 className='text-center'>Insurance : why is it so important</h2>
        <div style={{ textAlign: '-webkit-center' }}>
          <p className='para ' style={{textAlign:'Justify'}}>We live in an uncertain world. Yet own many precious things like cars, properties and to top it all our Health and Life. So whether you bump your car or get hospitalized or need assistance on vacation or face something as grave as a terminal medical condition, insurance covers and compensates for any financial loss</p>
        </div>

      </div>
      <CardGroup>
        <Row>
      
            {IMPORTANCE_VALUES.map((values,index)=>{
                return(
                    <Col md={6} lg={3} sm={6} key={index} className='py-3 ' >
                    <InsuranceCard dettails={values}/>
                    </Col>
                )
            })}
          
        </Row>
        </CardGroup>
        </Container>
      
    </div>
  )
}

export default Importance; 

import React, { useRef } from "react";
import { Scrollspy } from "@makotot/ghostui";
// import '../../CSS/CrestJourney.css'
import "../../CSS/CrestJourney.css";

const TermsCondition = () => {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  return (
    <div className="py-md-5 py-4">
      <Scrollspy sectionRefs={sectionRefs}>
        {({ currentElementIndexInViewport }) => (
          <div className="d-md-flex ">
            <div
              className=" position-md-sticky top-0  "
              style={{ width: "300px" }}
            >
              <div
                className="  position-sticky top-0 t-v mt-md-5  pt-2"
                style={{ width: "300px" }}
              >
                <ul data-cy="nav-wrapper" className=" cursor-pointer mt-12">
                  <li
                    id="scroll"
                    className={
                      currentElementIndexInViewport === 1 ? "is-active" : ""
                    }
                  >
                    <a href={`#section-1`}>Terms of Usage</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 2 ? "is-active" : ""
                    }
                  >
                    <a href={`#section-2`}>Privacy Policy</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 3 ? "is-active" : ""
                    }
                  >
                    <a href={`#section-3`}>Disclaimer</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className=" px-md-5 px-4">
              <div data-cy="section-wrapper" className="px-md-3">
                <div
                  id={`section-1`}
                  ref={sectionRefs[1]}
                  className={
                    currentElementIndexInViewport === 1 ? "active" : ""
                  }
                >
                  <div className="">
                    <h6 className="text-center term-title">Terms of Usage</h6>
                    <div
                      className="box px-3"
                      style={{ height: "100%", width: "100%" }}
                    >
                      <p className="text-md leading-7 mt-4">
                        At Crest Insure, we love all things
                        &quot;insurance&quot;. We believe that the way insuranc
                        products have been distributed, over the years, is not
                        the best, and can be improved tremendously. It has also
                        caused Indians being under-insured
                      </p>
                      <p className="text-md leading-7 mt-4">
                        We understand that India struggles with 4 problems in
                        insurance distribution:
                      </p>
                      <p className="text-md leading-7 mt-4">
                        * Concentration of Insirance Activities in Metro & Tier
                        1 cities (70% business come from few big cities)
                      </p>
                      <p className="text-md leading-7 mt-4">
                        * Less Financial Literacy as people still struggle to
                        comprehend insurance properly
                      </p>
                      <p className="text-md leading-7 mt-4">
                        * Ad-Hoc and Incentive based insurance buying resulting
                        in improper planning and under insurance
                      </p>
                      <p className="text-md leading-7 mt-4">
                        * Unprofessional and Less trained advisors resulting in
                        improper Insurance Journey for buying insurance
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  id={`section-2`}
                  ref={sectionRefs[2]}
                  className={
                    currentElementIndexInViewport === 2 ? "active" : ""
                  }
                >
                  <div className="divOne mt-5">
                    <h6 className="text-center  term-title">Privacy Policy</h6>
                    <div
                      className="box px-3"
                      style={{ height: "100%", width: "100%" }}
                    >
                      <p className="text-md leading-7 mt-4">
                        At Crest Insure, we love all things
                        &quot;insurance&quot;. We believe that the way insuranc
                        products have been distributed, over the years, is not
                        the best, and can be improved tremendously. It has also
                        caused Indians being under-insured
                      </p>
                      <p className="text-md leading-7 mt-4">
                        We understand that India struggles with 4 problems in
                        insurance distribution:
                      </p>
                      <p className="text-md leading-7 mt-4">
                        * Concentration of Insirance Activities in Metro & Tier
                        1 cities (70% business come from few big cities)
                      </p>
                      <p className="text-md leading-7 mt-4">
                        * Less Financial Literacy as people still struggle to
                        comprehend insurance properly
                      </p>
                      <p className="text-md leading-7 mt-4">
                        * Ad-Hoc and Incentive based insurance buying resulting
                        in improper planning and under insurance
                      </p>
                      <p className="text-md leading-7 mt-4">
                        * Unprofessional and Less trained advisors resulting in
                        improper Insurance Journey for buying insurance
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  id={`section-3`}
                  ref={sectionRefs[3]}
                  className={
                    currentElementIndexInViewport === 3 ? "active" : ""
                  }
                >
                  <div className="divOne mt-5">
                    <h6 className="text-center  term-title">Disclaimer</h6>
                    <div
                      className="box px-3 "
                      style={{ height: "100%", width: "100%" }}
                    >
                      <p className="text-md leading-7 mt-4">
                        At Crest Insure, we love all things
                        &quot;insurance&quot;. We believe that the way insuranc
                        products have been distributed, over the years, is not
                        the best, and can be improved tremendously. It has also
                        caused Indians being under-insured
                      </p>
                      <p className="text-md leading-7 mt-4">
                        We understand that India struggles with 4 problems in
                        insurance distribution:
                      </p>
                      <p className="text-md leading-7 mt-4">
                        * Concentration of Insirance Activities in Metro & Tier
                        1 cities (70% business come from few big cities)
                      </p>
                      <p className="text-md leading-7 mt-4">
                        * Less Financial Literacy as people still struggle to
                        comprehend insurance properly
                      </p>
                      <p className="text-md leading-7 mt-4">
                        * Ad-Hoc and Incentive based insurance buying resulting
                        in improper planning and under insurance
                      </p>
                      <p className="text-md leading-7 mt-4">
                        * Unprofessional and Less trained advisors resulting in
                        improper Insurance Journey for buying insurance
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  id={`section-4`}
                  ref={sectionRefs[4]}
                  className={
                    currentElementIndexInViewport === 4 ? "active" : ""
                  }
                ></div>
              </div>
            </div>
          </div>
        )}
      </Scrollspy>
    </div>
  );
};

export default TermsCondition;

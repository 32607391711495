// import React, { useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import ModalShare from "../../ModalShare/ModalShare";
// import ModalImg from "../../Assets/Img/Headimg/ Modalimage.jpg";
// import { Row, Col } from "react-bootstrap";
// import Card from "react-bootstrap/Card";
// import {  FcPhoneAndroid } from "react-icons/fc";
// import { IoMdCall } from "react-icons/io";
 

//   function ModalInHome() {
//     const [show, setShow] = useState(false);

//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);
  
//   return (
//     <div> 
//           <Button className="callbtn " onClick={handleShow}>
//                 <IoMdCall /> Speak to Expert
//               </Button>

//          <Modal show={show} onHide={handleClose}>
//          <div className="ModalDiv">
//                   <div className="firstdiv d-flex     ">
//                     <div className="ImageText   ">
//                       <div>
//                         <img src={ModalImg} alt="" width="40%" height="40%" style={{borderRadius:"10px"}} />{" "}
//                       </div>
//                       <div className="my-2  h-100 w-100 p-4">
//                         <h4> What is Lorem Ipsum?</h4>  
//                         <p className=" "  >this is a long
//                         established fact that a reader will  be distracted by the
//                         readable content of a page when looking at its layout.
//                         firstdiv</p>
//                       </div>
//                     </div>
//                     <div>
//                       <Button
//                         variant="light"
//                         className="btn-close btn1  "
//                         aria-label="close"
//                         onClick={handleClose}
//                       ></Button>
//                     </div>
//                   </div>
//                   <Row className="modalCardsRow">
//                     {" "}
//                     <Col className="my-2">
//                       <Card style={{borderRadius:"10px"}}>
//                         <Card.Body>
//                           <Card.Title>    < FcPhoneAndroid  className="mb-1 " size="30px" /> <br/>
//                            Contact Us</Card.Title>
//                           <Card.Text>
//                             Some quick example text to build on the card title
//                             and make up the bulk of the card's content.
//                           </Card.Text>

//                           <Button variant="primary">
//                             {" "}
//                             <IoMdCall className="me-1"  /> Call Us
//                           </Button>
//                         </Card.Body>
//                       </Card>
//                     </Col>
//                     <Col className="my-2 ">
//                       <Card   style={{borderRadius:"10px"}}   > 
//                         <Card.Body>
//                           <Card.Title>   <div className=" mb-2">
//                               {" "}
//                               {/* <ModalShare className="" /> */}
//                             </div>{" "}
//                              Whatsapp Us  </Card.Title>
//                           <Card.Text>
//                             Some quick example text to build on the card title
//                             and make up the bulk of the card's content.
//                           </Card.Text>

//                           <Button
//                             variant="success"
//                             className="d-flex  "
//                             onClick={ModalShare}
//                           >
//                             <div className="me-1">
//                               {" "}
//                               {/* <ModalShare className="" /> */}
//                             </div>{" "}
//                             Whatsapp Us
//                           </Button>
//                         </Card.Body>
//                       </Card>
//                     </Col>
//                   </Row>
//                 </div>
//       </Modal>
//     </div>
//   )
// }


//  export default ModalInHome;

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import ModalShare from "../../ModalShare/ModalShare";
import ModalImg from "../../Assets/Img/Headimg/ Modalimage.jpg";
import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import {  FcPhoneAndroid } from "react-icons/fc";
import { IoMdCall } from "react-icons/io";
import { IoLogoWhatsapp } from 'react-icons/io';
import {ImWhatsapp} from "react-icons/im"
  

  function ModalInHome() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
  return (
    <div> 
          <Button className="callbtn " onClick={handleShow}>
                <IoMdCall /> Speak to Expert
              </Button>

         <Modal show={show} onHide={handleClose}>
         <div className="ModalDiv">
                  <div className="firstdiv d-flex     ">
                    <div className="ImageText   ">
                      <div>
                        <img src={ModalImg} alt="" width="40%" height="40%" style={{borderRadius:"10px"}} />{" "}
                      </div>
                      <div className="my-2  h-100 w-100 p-4">
                        <h4> What is Lorem Ipsum?</h4>  
                        <p className=" "  >this is a long
                        established fact that a reader will  be distracted by the
                        readable content of a page when looking at its layout.
                        firstdiv</p>
                      </div>
                    </div>
                    <div>
                      <Button
                        variant="light"
                        className="btn-close btn1  "
                        aria-label="close"
                        onClick={handleClose}
                      ></Button>
                    </div>
                  </div>
                  <Row className="modalCardsRow">
                    {" "}
                    <Col className="my-2">
                      <Card style={{borderRadius:"10px"}}>
                        <Card.Body>
                          <Card.Title>    < FcPhoneAndroid  className="mb-1 " size="30px" /> <br/>
                           Contact Us</Card.Title>
                          <Card.Text>
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </Card.Text>

                         <a href="tel:+919911560303"> <Button variant="primary">
                            <IoMdCall className="me-1"  /> Call Us
                          </Button></a>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col className="my-2 ">
                      <Card   style={{borderRadius:"10px"}}   > 
                        <Card.Body>
                          <Card.Title>   <div className=" mb-2">
                              {" "}
                              {/* {/ <ModalShare className="" /> /} */}
                            </div>{" "}
                               <ImWhatsapp  className="mb-1" size="20px"/> <br/>
                                  Whatsapp Us  </Card.Title>
                          <Card.Text>
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </Card.Text>
                          <div><a target='_blank' href="https://wa.me/919911570303"><button className="btn btn-success ms-1  text-white rounded-2" ><IoLogoWhatsapp size={25} className='pb-1' /> Whatsapp Us</button></a></div> 
                          {/* <Button
                            variant="success"
                            className="d-flex  "
                            // onClick={ModalShare}
                          >
                            <div className="me-1">
                              {" "}
                              <ModalShare className="" />
                            </div>{" "}
                            Whatsapp Us
                          </Button> */}
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
      </Modal>
    </div>
  )
}


 export default ModalInHome;
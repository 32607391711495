import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import People_placeholder from '../../Assets/Img/People_behind_crest/People_placeholder.jpg'
import { BsLinkedin } from 'react-icons/bs';
import Card from 'react-bootstrap/Card';

import '../../CSS/BehindCrest.css'



const Behind_crest = () => {
    return (
        <>
            <Container className='my-2  py-2 '>
                <Card className='my-3 card-hover'>
                    <Row className='align-items-center justify-content-center my-4  p-1'>

                        <Col lg={2} md={3} sm={4} className='my-auto'>

                            <div className='coloum text-center text-md-end'><img src={People_placeholder} alt="" width={'150px'} height={'150px'} className='border rounded-circle' /></div>

                        </Col>
                        <Col lg={10} md={6} sm={6} className='my-3 my-md-0'>
                            <div><h6>Manu | Founder & CEO</h6></div>
                            <div>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo eius placeat, maxime animi possimus officia autem fuga amet a doloribus molestias qui, nam voluptatibus totam reiciendis consectetur atque rerum et.</div>
                            <div><BsLinkedin color='rgb(40,103,178)' size={20} /></div>
                            
                        </Col>

                    </Row>
                </Card>

                <Card className='my-3 card-hover'>
                    <Row className='align-items-center justify-content-center my-4  p-1'>

                        <Col lg={2} md={3} sm={4} className='my-auto'>

                            <div className='coloum text-center text-md-end'><img src={People_placeholder} alt="" width={'150px'} height={'150px'} className='border rounded-circle' /></div>

                        </Col>
                        <Col lg={10} md={6} sm={6} className='my-3 my-md-0'>
                            <div><h6>Manu | Founder & CEO</h6></div>
                            <div>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo eius placeat, maxime animi possimus officia autem fuga amet a doloribus molestias qui, nam voluptatibus totam reiciendis consectetur atque rerum et.</div>
                            <div><BsLinkedin color='rgb(40,103,178)' size={20} /></div>
                        </Col>

                    </Row>
                </Card>
                <Card className='my-3 card-hover'>
                    <Row className='align-items-center justify-content-center my-4  p-1'>

                        <Col lg={2} md={3} sm={4} className='my-auto'>

                            <div className='coloum text-center text-md-end'><img src={People_placeholder} alt="" width={'150px'} height={'150px'} className='border rounded-circle' /></div>

                        </Col>
                        <Col lg={10} md={6} sm={6} className='my-3 my-md-0'>
                            <div><h6>Manu | Founder & CEO</h6></div>
                            <div>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo eius placeat, maxime animi possimus officia autem fuga amet a doloribus molestias qui, nam voluptatibus totam reiciendis consectetur atque rerum et.</div>
                            <div><BsLinkedin color='rgb(40,103,178)' size={20} /></div>
                        </Col>

                    </Row>
                </Card>
                <Card className='my-3 card-hover'>
                    <Row className='align-items-center justify-content-center my-4  p-1'>

                        <Col lg={2} md={3} sm={4} className='my-auto'>

                            <div className='coloum text-center text-md-end'><img src={People_placeholder} alt="" width={'150px'} height={'150px'} className='border rounded-circle' /></div>

                        </Col>
                        <Col lg={10} md={6} sm={6} className='my-3 my-md-0'>
                            <div><h6>Manu | Founder & CEO</h6></div>
                            <div>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo eius placeat, maxime animi possimus officia autem fuga amet a doloribus molestias qui, nam voluptatibus totam reiciendis consectetur atque rerum et.</div>
                            <div><BsLinkedin color='rgb(40,103,178)' size={20} /></div>
                        </Col>

                    </Row>
                </Card>


            </Container>
        </>
    )
}

export default Behind_crest;

import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../../CSS/Tab.css'


const Career_Tabs = () => {
  let navigate =useNavigate();

  const handleEntrepreneur = () => { 
    navigate ('/career-in-insurance/entrepreneur')
  }

  const handleWork =()=>{
    return navigate ('/career-in-insurance/parttime-work')
  }

  const handleAdvisor =()=>{
    return navigate ('/career-in-insurance/pos-advisor')
  }

  return (
    <div className='row d-flex justify-content-center my-4 mx-3'>
      
   
       <div onClick={handleEntrepreneur}  className='routes'> As An Entrepreneur</div>
       <div onClick={handleWork} className='routes'>  Part Time Work</div>
       <div onClick={handleAdvisor} className='routes'> Pos Advisor</div>
    
 

    </div>
  )
}

export default Career_Tabs;
import React, { useRef } from 'react'
import Footer from '../../Components/Common/Footer/Footer'
import Navbars from '../../Components/Common/Navbar/Navbars'
import PoliciesTabs from '../../Components/PoliciesTabs/PoliciesTabs'
import { Scrollspy } from "@makotot/ghostui";
import ScrollToTop from '../../Components/ScrollControl/ScrollControl';
import Button from 'react-bootstrap/Button';
import { Container, Col, Row, Card } from 'react-bootstrap';


const Motor_Insurance = () => {

  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),

  ];


  return (
    <div>
      <Navbars />
      <PoliciesTabs />
      <div className='text-center'>
        <h1>Term Insurance</h1>
      </div>
      <Scrollspy sectionRefs={sectionRefs}>
        {({ currentElementIndexInViewport }) => (
          <div className='d-md-flex '>
            <div className=" position-md-sticky top-5 " style={{ width: '300px' }}>
              <div className="  position-sticky t-v pt-md-5 pt-2" style={{ width: '300px' }} >
                <ul
                  data-cy="nav-wrapper"
                  className="space-y-5 cursor-pointer mt-12"
                >
                  <li
                    className={
                      currentElementIndexInViewport === 1
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-1`}>What is Term Insurance</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 2
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-2`}>Why must you take Term Insuranc</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 3
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-3`}>How much insurance cover is good</a>
                  </li>
                  {/* <li
                    className={
                      currentElementIndexInViewport === 4
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-4`}>Corporate Insurance</a>
                  </li> */}
                  <li
                    className={
                      currentElementIndexInViewport === 5
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-5`}>When is the right time to take Term Insurance</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 6
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-6`}>What should be ideal insurance cover period (policy period)</a>
                  </li>

                  <li
                    className={
                      currentElementIndexInViewport === 7
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-7`}>Waiver of Premium</a>
                  </li>

                  <li
                    className={
                      currentElementIndexInViewport === 8
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-8`}>Accidental Death Benefit</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 9
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-9`}>Critical / Terminal Illness Benefit</a>
                  </li>
                 
                </ul>
              </div>
            </div>
            <div className=" px-md-5 px-4">
              <div data-cy="section-wrapper" className='px-md-3'>
                <div
                  id={`section-1`}
                  ref={sectionRefs[1]}
                  className={
                    currentElementIndexInViewport === 1 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mt-md-5 mt-4"
                    id="entrepreneur"
                  >
                   <h2> Terms Condition </h2>
                  </div>
                  <p>All the world's a stage; And all the men and women merely players; They have their exits and their entrances</p>
                  <p className='ps-5' style={{opacity:'50%'}}><b>- William Shakespear</b></p>
                  <p>Let’s understand some scenarios:</p>
                  <p>Someone who recently graduated and enters the job market (office) with a dream of getting some luxury items for selfalso feels responsible to give back to his/her parents a great life in their old age.</p>
                  <p>Someone who recently got married, feels obliged to secure a great future for his / her spouse, as both love one another so much. Their priority shifts to getting settled in life by owning a House and a Car on EMI and may be purchasing some lifestyle items, etc.</p>
                  <p>Someone who got their first baby in his/her life, starts planning for good education and a decent wealth for his/her family. He / She also wishes to keep a decent amount ready for any emergency for family.</p>
                  <p>Someone getting into their mid-30s, starts planning for his/her house, retirement planning, good wealth corpus, best education and settled life for their kidsand a settled life for himself and spouse</p>
                  <p>Further, with advent of Buy Now, Pay Later and the concept of EMI, it sounds so easy to own anything in the world, ifone has the power to pay for them gradually.</p>
                  <p>(Did you think that we are advocating EMI or BNPL…… Hell no!)</p>
                  <p>It sounds so good to hear about our goals and dreams and owning things that we desired to always own.</p>
                  <p>But we keep forgetting about the fact, that everything – our dreams and goals, would happen if we are there to fulfil them and pay for them.</p>
                  <p><b>What if, you are not there … What would happen to your goals or dreams that you saw for your family – kids, spouse, and parents</b></p>
                  <p>A Term insurance comes handy in here. It assures and guarantees the goals and dreams that you have seen for your family when you are not there to fulfil them.</p>
                  <p>It provides the required finance to your family, in your absence, to fulfil the dreams and goals that you once jointly saw for them</p>
                  <br />

                </div>
                <div
                  id={`section-2`}
                  ref={sectionRefs[2]}
                  className={
                    currentElementIndexInViewport === 2 ? "active" : ""
                  }
                >
                  <div
                    className=" journey-title text-center mb-1 mt-md-5 mt-4"
                    id="parttime"
                  >
                   <h2> Why must you take Term Insurance </h2>
                  </div>
                  <p>People are remembered even after their death, for their good deeds!</p>
                  <p className='ps-5' style={{opacity:'50%'}}><b>- old saying</b></p>
                  <p>Term Insurance or Term Plan, we believe is the good deed that one must do for their family </p>
                  <p>Because you love your family and hence it is your duty to provide for them even when you are not there for them. Agreed that no one wants to plan for their death, but it takes character and guts to plan for your loved ones, ones with whom you have seen many good times and with one you have seen many dreams, for the times when you are not there for them. Term plan helps you plan for these trying times for your loved ones.</p>
                  <p>Term plan cannot and does not replace you and your love for them, but at least, provide finance in your absence.</p>
                  <p>Further, it is said that with peace in mind, one can achieve wonders. Term Insurance provides that peace of mind to you. It gives you those wings to fly without worrying anything about the financial well-being of your loved ones, with or without you. So, with one big worry taken care of, you are sure to rise way above the normal.</p>
                  <p>Besides, such amazing of assuring and guaranteeing your gaols and dreams, and being there for your family, when you are not there, Term Insurance also offers amazing Tax Benefit u/s 80 (C) of Income tax Act.</p>
                </div>

                <div
                  id={`section-3`}
                  ref={sectionRefs[3]}
                  className={
                    currentElementIndexInViewport === 3 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1 mt-md-5 mt-4"
                    id="fulltime"
                  >
                    <h2>How much insurance cover is good</h2>
                  </div>
                  <p>The one-liner, as much as your family needs to fulfil the dreams and pay of the liabilities.</p>
                  <p>On a more granular view,there are 4 rules to choosing how much insurance cover should you buy:</p>
                  <p ><b><u>Cover dreams & Goals</u></b></p>
                  <p>Insurance cover should be enough to fulfil all dreams and goals that you and your family jointly saw and planned</p>
                  <p><b><u>Pay off your Loans & Liabilities</u></b></p>
                  <p>No one wants to pass on their liabilities and loans outstanding, like EMI for House, car, etc. to their families. Your insurance cover should be enough to pay off your current loans & liabilities</p>
                  <p><b><u>Help your family maintain their lifestyle</u></b></p>
                  <p>You have given a comfortable and nice lifestyle to your family, and we are sure you would not want any compromise to that either, whether you are there or not. Your insurance cover should provide for a good lifestyle for your family, even after you have gone from their lives</p>
                  <p><b><u>Inflation must be considered</u></b></p>
                  <p>We all have seen how cost of living and cost of goods rise over a period, because of inflation. 1 crore of insurance cover that might look great deal today, might turn out to be a meagre figure 10 years from now. Since, you are planning for future eventualities, hence try taking a higher cover</p>
                  <p><b>Don’t these look some sort of number crunching…. Surely, they do!</b></p>
                  <p>But, no worries, we have got your back. At Crest, we are building Crest Insure Journey, a sort of calculator that would answer not just these queries, but also suggest the best plan for you</p>
                  <p>Besides, our experts are always there to guide and assist you in navigating this journey</p>

                  <div> <Button style={{ background: "#429efb" }}>Talk to Us</Button>{' '}</div><br />
                  <p>Didn’t we say that we are here to make things<b>Simple, Un-biased and Scientific</b></p>
                </div>



                <div
                  id={`section-5`}
                  ref={sectionRefs[5]}
                  className={
                    currentElementIndexInViewport === 5 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1 mt-md-5 mt-4"
                    id="ds"
                  >
                  <h2> When is the right time to take Term Insurance </h2>
                  </div>
                  <p>The best time to get protected and start investment was yesterday, next best is today</p>
                        <p className='ps-5' style={{opacity:'50%'}}><b>- Popular saying</b></p>
                        <p>Beyond the cliché, the right time to buy an insurance cover is:</p>
                        <p>Either the young age</p>
                        <p>Or</p>
                        <p>The time when you plan for additional goals or increase your liabilities</p>
                        <p><b><u>The Young Age</u></b></p>
                        <p>Term Insurance are less flexible. Once you buy the cover, their duration, amount of cover, options that you have chosen and premium that you pay, all generally remains as is, till the entire policy period.</p>
                        <p>At the young age, since you are healthier, generally speaking, and chances of living long is also high, hence premium amount charged by insurers is also less.</p>
                        <p>Further, Insurance cover is a means to protect and secure your goals and goal planning generally stats early. Hence, moment you decide for a goal or plan anything for yourself or your family, you must secure them with an insurance cover.</p>
                        <p><b><u>Whenever you add to your goal or increase your liability</u></b></p>
                        <p>Your insurance need can increase, in case you plan additional goals later or assume additional liabilities. For example, if you buy a second house later on loan, your liability gets increase later, and you must secure them with adequate insurance cover. Similarly, if you plan for a second child your goals for him/her would alsomean securing them with insurance cover.</p>
                        <p>Want some advice on planning your finances and insurance needs, our experts can surely help you</p>

                        <div> <Button style={{ background: "#429efb" }}>Talk to Us</Button>{' '}</div><br />
                </div>


                <div
                  id={`section-6`}
                  ref={sectionRefs[6]}
                  className={
                    currentElementIndexInViewport === 6 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1 mt-md-5 mt-4"
                    id="ds"
                  >
                  <h2>  What should be ideal insurance cover period (policy period) </h2>
                  </div>
                  <p>There are many theories abound this. Some people want a whole life policy, because they believe a life cover should be for 100 years. Some people want it for 60 years, because that is the general age of retirement for most service class people. Some people consider the human life expectancy for Indians, which is 70 years to be the insurance period.Generally speaking, 60 – 70 years can be a good insurance cover term for people.</p>
                            <p>But, remember, wediscussed providing for goals and paying off debts, in case you are not there for your family. So, your exact insurance cover period should be as much to cover for all your goals and liabilities. This surely requires some kind of calculation and as discussed earlier our Crest Insure Journey, a sort of calculator we are building, would answer not these queries and suggest the best plan for you.</p>
                            <p>Besides, our experts are always there to guide and assist you in navigating this journey</p>
                            <div> <Button style={{ background: "#429efb" }}>Talk to Us</Button>{' '}</div><br />
                            <p><b><u>Term Insurance Check list</u></b></p>
                            <p>While rule of thumb says that you should cover all your dreams, goals, and liabilities through Term insurance. However, there are certain riders or add-on (as they are popularly called) that provides additional layer of security to your plan. Here’s a look at some of them:</p>
                            <p>You may check subsequent sections for their details. These riders come with a small fee, but come in quite handy.</p>

                            <Row >
                                <Col sm={12} lg={6} md={6} className='my-2'>
                                    <Card className='w-100 h-100 p-2'>
                                        <h4 className='text-center'>Waiver of Premium</h4>
                                        <p className='text-center'>In case of a Permanent Disability or in Certain Critical Illness, many insurers waive off your premium and your policy still continues</p>
                                    </Card>
                                </Col>
                                <Col sm={12} lg={6} md={6} className='my-2'>
                                    <Card className='w-100 h-100 p-2'>
                                        <h4 className='text-center'>Accidental Death Benefit</h4>
                                        <p className='text-center'>In case one dies in accident, insurer provideswith extra cover (over and above the normal insurance cover one opted)</p>
                                    </Card>
                                </Col>
                            </Row><br />

                            <Row>
                                <Col lg={6} md={6} className='my-2'>
                                    <Card className='w-100 h-100 p-2'>
                                        <h4 className='text-center'>Critical / Terminal Illness Benefit</h4>
                                        <p className='text-center'>
                                            If a person is diagnosed with some critical Illnesses like cancer, etc. insurers pay them additional amount to take care of their illness and go for desired treatment
                                        </p>
                                    </Card>
                                </Col>
                                <Col lg={6} md={6} className='my-2'>
                                    <Card className='w-100 h-100 p-2'>
                                        <h4 className='text-center'>Increasing Cover / Life Stage Benefit</h4>
                                        <p className='text-center'>This add-on rider provides for increasing inflation or certain life events with an increasing cover to the Life Insured</p>
                                    </Card>
                                </Col>
                            </Row>



                            <p>Didn’t we say that we are here to make things <b>Simple, Un-biased and Scientific</b></p>


                </div>


                <div
                  id={`section-7`}
                  ref={sectionRefs[7]}
                  className={
                    currentElementIndexInViewport === 7 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1 mt-md-5 mt-4"
                    id="ds"
                  >
                  <h2>Waiver of Premium</h2>
                  </div>
                  <p>Imagine a situation where one become Permanently Disabled. His earnings goes for a spin, and to make it worse, he has to pay for premium of the policy that he / she has bought to protect the family.</p>
                                <p>Isn’t it a double whammy… … Surely, it is!</p>
                                <p>In case you have opted for this add-on rider, insurers waiveoff your premium, for the policy, for rest of the period, in this case. Insurers charge you a very small fee for this.</p>
                                <p>This add-on riderreinforces your peace of mindby ensuring that yourhard earned money in saved tough times. We recommend taking this rider.</p>
                </div>

                <div
                  id={`section-8`}
                  ref={sectionRefs[8]}
                  className={
                    currentElementIndexInViewport === 8 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1 mt-md-5 mt-4"
                    id="ds"
                  >
                  <h2> Accidental Death Benefit </h2>
                  </div>
                  <p>It is a fact that accidents are one of the major cause of deaths in India . This add-on rider comes in handy for family in case of accidental deaths.</p>
                                    <p>Simply put, insurers pay additional cover to family, Ifyou die in an accident. So, in normal death condition, you get the normal insurance cover, in case of accident, you get Normal + Accidental Death Cover.</p>
                                    <p>Let us understand this with an example. If you have taken a Term plan with 1 crore of normal cover and additional 50 lakhs of Accidental Death Benefit. So, in case of normal death, your family would get 1 crore insurance amount, however in case of accidental death, your family would get 1.50 crore of insurance amount.</p>
                </div>

                <div
                  id={`section-9`}
                  ref={sectionRefs[9]}
                  className={
                    currentElementIndexInViewport === 9 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1 mt-md-5 mt-4"
                    id="ds"
                  >
                  <h2> Critical / Terminal Illness Benefit </h2>
                  </div>
                  <p>Another interesting fact is that Cancer, one of the Critical Illness listed there, is a major cause of death. Further, many people who get detected for cancer, end up paying a very hefty money for their treatment. It is another fact, that many lives are lost because of not adequate treatment of cancer and other critical illness.</p>
                                        <p>How would you feel it, if insurer gives you additional amount on diagnosis of any of the listed Critical Illness to provide for your treatment?Wouldn’t it be amazing?</p>
                                        <p>By paying a small extra fee, along with your normal cover, you also get Critical Illness cover.This cover provides for treatment of your Critical Illness and hence we recommend taking this.</p>
                </div>

             
              </div>


            </div>

          </div>

        )}
      </Scrollspy>
      <ScrollToTop />
      <Footer />
    </div>
  )
}

export default Motor_Insurance;

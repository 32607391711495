import React, { useRef } from 'react'
import Footer from '../../Components/Common/Footer/Footer'
import Navbars from '../../Components/Common/Navbar/Navbars'
import PoliciesTabs from '../../Components/PoliciesTabs/PoliciesTabs'
import { Scrollspy } from "@makotot/ghostui";
import ScrollToTop from '../../Components/ScrollControl/ScrollControl';

const Corporate_Insurance = () => {

 const sectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        
      ];


  return (
    <div>
    <Navbars/>
    <PoliciesTabs/>
    <div>       <h1 className="text-center mt-4">Corporate Insurance</h1></div>
    <Scrollspy sectionRefs={sectionRefs}>
        {({ currentElementIndexInViewport }) => (
          <div className='d-md-flex '>
            <div className=" position-md-sticky top-5 " style={{ width: '300px' }}>
              <div className="  position-sticky t-v   pt-md-5 pt-2" style={{ width: '300px' }} >
                <ul
                  data-cy="nav-wrapper"
                  className="space-y-5 cursor-pointer mt-12"
                >
                  <li
                    className={
                      currentElementIndexInViewport === 1
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-1`}>What is Health Insurance</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 2
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-2`}>Why must you take Health Insurance</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 3
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-3`}>Health Insurance Check list</a>
                  </li>
                  {/* <li
                    className={
                      currentElementIndexInViewport === 4
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-4`}>Corporate Insurance</a>
                  </li> */}
                     <li
                    className={
                      currentElementIndexInViewport === 5
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-5`}>Health Benefits</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 6
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-6`}>Free Health Check up</a>
                  </li>

                  <li
                    className={
                      currentElementIndexInViewport === 7
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-7`}>Bonus or Additional Cover amount for making no claim</a>
                  </li>

                  <li
                    className={
                      currentElementIndexInViewport === 8
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-8`}>Domiciliary Treatment Facilities</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 9
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-9`}>Alternative Treatment like Unani, Homoeopathy, etc</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 10
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-10`}>Know Top Up Plans</a>
                  </li>
                  <li
                    className={
                      currentElementIndexInViewport === 11
                        ? "is-active"
                        : ""
                    }
                  >
                    <a href={`#section-11`}>Enhances your covers multiple times</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className=" px-md-5 px-4">
              <div data-cy="section-wrapper" className='px-md-3'>
                <div
                  id={`section-1`}
                  ref={sectionRefs[1]}
                  className={
                    currentElementIndexInViewport === 1 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center"
                    id="entrepreneur"
                  >
             Corporate
                  </div>
                  {/* <p className=" journey-Subtitle leading-relaxed text-lg caps text-gray-800  mb-4 text-center">
                    <span><i> Bridging the Indian Protection Gap</i></span>
                  </p> */}

                
                </div>
                <div
                  id={`section-2`}
                  ref={sectionRefs[2]}
                  className={
                    currentElementIndexInViewport === 2 ? "active" : ""
                  }
                >
                  <div
                    className=" journey-title text-center mb-1"
                    id="parttime"
                  >
                   Life Insurance
                  </div>
                  {/* <p className="journey-Subtitle caps leading-relaxed text-lg text-gray-800  mb-4 text-center">
                    <span><i> Simple | Unbiased | Scientific</i></span>
                  </p> */}
                  <div className="journey-simple">
                    Simple
                  </div>
                  <p className="text-md leading-7 mt-4">
                    We believe that the solution to India&apos;s protection gap lies in making insurance and financial
                    planning simple and easy to understand. We have made it simple through our content offerings
                    in the language of your choice and in the way you want - video and blogs.
                  </p>
                
              
                  <p className="text-md leading-7 mt-4">
                    Our trained advisor partners use the journey to esnure that product that best suit your
                    requirments, only gets recommended removing any bias
                  </p>
                </div>

                <div
                  id={`section-3`}
                  ref={sectionRefs[3]}
                  className={
                    currentElementIndexInViewport === 3 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1"
                    id="fulltime"
                  >
                   Motor Insurance
                  </div>
               
                 
               
                  <p className="text-md leading-7 mt-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Fusce volutpat accumsan volutpat. Sed viverra
                    nisl at consectetur
                  </p>
                </div>

             
               
                <div
                  id={`section-5`}
                  ref={sectionRefs[5]}
                  className={
                    currentElementIndexInViewport === 5 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1"
                    id="ds"
                  >
                      Insurance 
                  </div>
                
                 
                  <p className="text-md leading-7 mt-4">
                    * Policies for schools, hospitals and shopping malls
                  </p>
                </div>


                <div
                  id={`section-6`}
                  ref={sectionRefs[6]}
                  className={
                    currentElementIndexInViewport === 6 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1"
                    id="ds"
                  >
                      Insurance 
                  </div>
                  <p className=" journey-Subtitle leading-relaxed caps text-lg text-gray-800  mb-4 text-center">
                    <span><i>Product For Every Indian Customer Need</i></span>
                  </p>
                  <p className="text-md leading-7 mt-4">
                    At Crest Insure, we want to protect every Indian&apos;
                    need. We would offer product in following categories
                    to serve the Indian needs:
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Health Insurance
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Motor Insurance
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Travel Insurance
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Term Insurance
                  </p>
                
                
                </div>


                <div
                  id={`section-7`}
                  ref={sectionRefs[7]}
                  className={
                    currentElementIndexInViewport === 7 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1"
                    id="ds"
                  >
                      Insurance 
                  </div>
                 
                  <p className="text-md leading-7 mt-4">
                    * Health Insurance
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Motor Insurance
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Travel Insurance
                  </p>
                
                
                </div>

                <div
                  id={`section-8`}
                  ref={sectionRefs[8]}
                  className={
                    currentElementIndexInViewport === 8 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1"
                    id="ds"
                  >
                      Insurance 
                  </div>
                 
               
                  <p className="text-md leading-7 mt-4">
                    * Policies for schools, hospitals and shopping malls
                  </p>
                </div>

                <div
                  id={`section-9`}
                  ref={sectionRefs[9]}
                  className={
                    currentElementIndexInViewport === 9 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1"
                    id="ds"
                  >
                      Insurance 
                  </div>
                 
                  <p className="text-md leading-7 mt-4">
                    * Policies for professional
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Policies for schools, hospitals and shopping malls
                  </p>
                </div>

                <div
                  id={`section-10`}
                  ref={sectionRefs[10]}
                  className={
                    currentElementIndexInViewport === 10 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1"
                    id="ds"
                  >
                      Insurance 
                  </div>
                 
                 
                  <p className="text-md leading-7 mt-4">
                    * Fire and Burglary Policies
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Policies for offices, factories and employers
                  </p>
                
                </div>

                <div
                  id={`section-11`}
                  ref={sectionRefs[11]}
                  className={
                    currentElementIndexInViewport === 11 ? "active" : ""
                  }
                >
                  <div
                    className="journey-title text-center mb-1"
                    id="ds"
                  >
                      Insurance 
                  </div>
                  <p className=" journey-Subtitle leading-relaxed caps text-lg text-gray-800  mb-4 text-center">
                    <span><i>Product For Every Indian Customer Need</i></span>
                  </p>
                  <p className="text-md leading-7 mt-4">
                    At Crest Insure, we want to protect every Indian&apos;
                    need. We would offer product in following categories
                    to serve the Indian needs:
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Health Insurance
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Motor Insurance
                  </p>
                  <p className="text-md leading-7 mt-4">
                    * Travel Insurance
                  </p>
                
                </div>
               
              </div>
              
             
            </div>
           
          </div>
          
        )}
      </Scrollspy>
      <ScrollToTop/>
      <Footer/>
    </div>
  )
}

export default Corporate_Insurance;

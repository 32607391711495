


import React from 'react'
import Logo from '../../../Assets/Img/Logo/logo.png'
import { Container, Row, Col } from 'react-bootstrap';
import '../../../CSS/Footer.css';
import { IoMdCall } from 'react-icons/io';
import { IoLogoWhatsapp } from 'react-icons/io';
import { FaFacebook } from 'react-icons/fa';
import { SiInstagram } from 'react-icons/si';
import { BsTwitter } from 'react-icons/bs';
import { AiFillYoutube } from 'react-icons/ai';
import { BsLinkedin } from 'react-icons/bs';
import { Link, useNavigate } from "react-router-dom";
import ModalShare from '../../../ModalShare/ModalShare';


const Footer = () => {
    let navigate = useNavigate();

    const handleFaq = () => {
        navigate('/necessary-question')
    }

    const handleClick = () => {
        navigate('/terms')
    }
    const handleHome = () => {
        navigate('/home')
    }

    return (
        <>
            <Container className='text-center my-2 '>
                <Row>
                    <Col md={4} xs={12} sm={6} className='' > <div onClick={handleHome} className='d-flex logo'><div className=' text-start '><img src={Logo} alt='' className='logoImage' /></div>

                    </div>
                        <div className='logo mt-2    '>
                            <div className='logo-container mt-2 text-start '> <p> At Crest, we are changing the paradigm of insurance selling and buying by making it simple, scientific and unbiased	</p>
                            </div>
                        </div>
                    </Col>

                    <Col md={3} xs={12} sm={6} className='mt-4'><div className='text-start'><h5>Product</h5></div>

                        <div className='text-start ' > <a className='text-decoration-none text-black' ><p><Link to="/policies/term-insurance">   Term Insurance </Link></p></a></div>
                        <div className='text-start  '> <a className='text-decoration-none text-black' ><p><Link to="/policies/health-insurance" > Health Insurance</Link> </p></a></div>
                        <div className='text-start  '> <a className='text-decoration-none text-black' ><p><Link to="/policies/motor-insurance"> Motor Insurance</Link> </p></a></div>
                        <div className='text-start '> <a className='text-decoration-none text-black' ><p><Link to="/policies/corporate-insurance">Corporate Insurance</Link> </p></a></div>

                    </Col>
                    <Col md={2} xs={12} sm={6} className='mt-4'><div className='text-start'><h5>Know More</h5></div>
                        <div className='text-start ' > <a className='text-decoration-none text-black' href="/"><p><Link to='/career-in-insurance/entrepreneur'> Career in Insurance</Link></p></a></div>
                        <div className='text-start'  >
                            <Link to='/necessary-question' className='text-decoration-none text-black'  > FAQ</Link></div>

                    </Col>
                    <Col md={1} xs={0}></Col>

                    <Col md={2} xs={12} sm={6} className='mt-4'><div className='text-start '><h5>General</h5></div>
                        <div className='text-start ' > <a className='text-decoration-none text-black' ><p><Link to='/work-with-crest'> About Us</Link></p></a></div>
                        <div className='text-start  ' onClick={handleClick}> <a className='text-decoration-none text-black'><p><Link to='/terms'> T&C</Link></p></a></div>
                        <div className='text-start  '> <a className='text-decoration-none text-black'><p> Privacy Policy</p></a></div>

                    </Col>


                </Row>
                <Row>
                    <Col md={3} xs={12} className='Button-col'>
                        <div className=''>
                            <div className='buttons text-start d-flex'>
                                <div> <a href="tel:+919911560303"> <button className="btn btn-info style ps-2   text-white rounded-2" ><IoMdCall size={25} className='pb-1' /> Call Us</button> </a></div>
                                <div><a target='_blank' href="https://wa.me/919911570303"><button className="btn btn-success ms-1  text-white rounded-2" ><IoLogoWhatsapp size={25} className='pb-1' /> Whatsapp Us</button></a></div>
                                {/* <div className=" ms-1" >< ModalShare className=""/></div> */}
                            </div>
                            <div className='my-1'><a href="tel:+919911560303">9911560303</a>|<a href="mailto:hello@crestinsure.com">hello@crestinsure.com</a></div>

                        </div>
                    </Col>
                    <Col md={9} xs={12}>
                        <div className=' '>
                            <div className='d-flex justify-content-md-end social-media pb-1 icons '>
                                <a target='_blank' href='https://www.facebook.com/crestinsure'> < FaFacebook size={30} className='mx-2 ' color='rgb(66,103,178)' /></a>
                                <a target='_blank' href='https://www.instagram.com/crest.insure/'> < SiInstagram size={30} className='mx-2' color='rgb(229,88,136)' /></a>
                                <a target='_blank' href="https://wa.me/919911560303">  < IoLogoWhatsapp size={30} className='mx-2' color='#198754' /></a>
                                {/* <a target='_blank' > < BsTwitter size={30} className='mx-2' color='rgb(29,161,242)' /></a> */}
                                <a target='_blank' href='https://www.youtube.com/crestinsure'> < AiFillYoutube size={30} className='mx-2' color='rgb(253,0,0)' /></a>
                                <a target='_blank' href='https://www.linkedin.com/company/crestinsure'>  < BsLinkedin size={30} className='mx-2' color='rgb(40,103,178)' /></a>

                            </div>

                        </div>

                    </Col>
                </Row> <hr />

                <Row>
                    <Col md={3}>





                        <div className='text-start my-5'> © Crest Insure <br />
                            CREST INSURANCE BROKERS PRIVATE LIMITED <br />
                            REG. ADDRESS:
                            PLOT-65, FIRST FLOOR, LANDMARK HOUSE, SECTOR 44, GURGAON, HARYANA - 122003 <br />
                            License category- Direct Broker (Life & General) <br />
                            IRDA  Registration No. 841, Registration Code No. IRDA/ DB 935/ 2022, Valid till 02/08/2025 <br />
                           
                        </div>
                    </Col>
                    <Col md={9} xs={12}>

                        <div className=' p-2 border-dark  my-1'>

                            <h4>Disclaimer</h4>
                            <div style={{ textAlign: "Justify" }}>
                                <p>
                                    We disclaim any and all liability/liabilities arising from hindrances, delays, stoppages and/or terminations of its products and/or services. Moreover, you acknowledge and understand that the products and/or services provided by us may not fulfill any and/or all of your necessity/necessities if they may so exist/arise. We disclaim any and all liability/liabilities arising from and/or related to our sharing and/or transfer of your Data and/or Information related thereto with Third-Party product and/or service providers. The Website disclaims all warranties, expressed or implied including, without limitation, all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement or arising from a course of dealing, usage, or trade practice. The company makes no representation about the suitability of the information, software, products, and services contained on this Website for any purpose, and the inclusion or offering of any products or services on this Website does not constitute any endorsement or recommendation of such products or services.

                                </p>
                            </div>
                        </div>

                    </Col>
                </Row>


            </Container>

        </>
    )
}

export default Footer

import React from "react";
import { WhatsappShareButton } from "react-share";
import { WhatsappIcon } from "react-share";
import { Button } from "react-bootstrap";

function ModalShare() {
  return (
    <div>
      <WhatsappShareButton url={"https://web.whatsapp.com/"} quote={"share it"}>
       
        <Button variant="success" className="d-flex">
          <div className="me-1" > <WhatsappIcon logoFillcolor="white" round={true}  size="25px" ></WhatsappIcon></div> Whatsapp Us
        </Button>
      </WhatsappShareButton>
    </div>
  );
}
export default ModalShare;
import React from 'react'
import Footer from '../../Components/Common/Footer/Footer'
import Navbars from '../../Components/Common/Navbar/Navbars'
import Reachus from '../../Components/ReachUs/ReachUs'
import ScrollToTop from '../../Components/ScrollControl/ScrollControl'
import Tabs from '../../Components/Tabs/Tabs'

const Contact = () => {
  return (
    <div>
      <Navbars/>
      <Tabs/>
      <Reachus/>
      <ScrollToTop/>
      <Footer/>
    </div>
  )
}

export default Contact;

import React, { useState } from "react";
import { Container } from "react-bootstrap";

import "../../CSS/ Homebanner.css";

import Image from "../../Assets/Img/HOME.png";

import { Row } from "react-bootstrap";

import ModalInHome from "./ModalInHome";
const Index = () => {
  return (
    <div>
      <Container>
        <div className="my-5">
          <Row>
            <div className=" col-lg-6 col-md-12 col-cm-12 col-12">
              <div className="row">
                <div className=" insurance   ">
                  <h1>Insurance made&nbsp;</h1>
                </div>
                <div
                  className=" 
                 slidingvertical textGroup font-bold"
                >
                  <span className=" simple">Simple</span>
                  <span className="Unbiased ">Unbiased</span>
                  <span className=" Scientific ">Scientific</span>
                </div>
              </div>
              <br />
              <p className="text">
                Your search for the right financial advice
                <b>
                  <s> ends </s>
                </b>
                begins here! Let the experts guide you. Get easily digestible
                knowledge nuggets to help you with insurance decisions, perfect
                for you and your family.
              </p>
              <p className="mb-0">
                Browse through our sections or ask us a doubt. We are there for
                you!
              </p>

              <div className="mt-3">
                {" "}
                <ModalInHome />{" "}
              </div>
            </div>
            <div className="  col-lg-6 col-md-12 col-cm-12 col-12 Home-banner-img  ">
              <img
                src={Image}
                alt=""
                className="homeimg"
                style={{ height: "100%" }}
              ></img>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Index;
